import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
// import userService from "src/services/user.service";
// import { useEffect } from "react";
import authService from 'src/services/auth.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const AdConnectDialog = ({ open, onClose, onSuccess }) => {
  const [clientId, setClientId] = useState('')
  const [secretId, setSecretId] = useState('')
  const [token, setToken] = useState('')

  const [error, setError] = useState(null)
  const [successSnack, setSuccessSnack] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleSave = () => {
    setLoading(true)
    authService
      .healthAD(clientId, secretId, token)
      .then(resp => {
        if (resp.data.status == 'CONNECTION_FAILURE') {
          setLoading(false)
          setError('Connection failure. Pelease check credentials')
        }
        if (resp.data.status == 'SUCCESSFULLY_CONNECTED') {
          authService
            .authorizeAD(clientId, secretId, token)
            .then(() => {
              setError(null)
              onClose()
              onSuccess()
              setLoading(false)
            })
            .catch(() => {
              setError('Something went wrong')
              setLoading(false)
            })
        }
      })
      .catch(error => {
        setLoading(false)
        setError(error.response.data.description)
      })
  }

  return (
    <>
      <Dialog
        sx={{ '& .MuiPaper-root': { borderRadius: '22px' } }}
        open={open}
        onClose={() => {
          onClose()
          setError(null)
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Active Directory integrations
        </DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: '#00000030',
                zIndex: '2'
              }}
              className='column column-center'
            >
              <LoadingIndicator text={'Please wait'} />
            </div>
          )}
          <p style={{ marginBottom: 24 }}>Please provide your AD details below</p>
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client ID'
            type='text'
            variant='standard'
            value={clientId}
            error={error}
            onChange={e => {
              setClientId(e.target.value)
              setError(null)
            }}
          />
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client Secret'
            type='text'
            variant='standard'
            value={secretId}
            error={error}
            onChange={e => {
              setSecretId(e.target.value)
              setError(null)
            }}
          />
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Tenant ID'
            type='text'
            variant='standard'
            value={token}
            error={error}
            onChange={e => {
              setToken(e.target.value)
              setError(null)
            }}
          />

          {error && (
            <p
              style={{
                marginTop: '-10px',
                marginBottom: '10px',
                fontSize: '14px'
              }}
              className='error-text'
            >
              {error}
            </p>
          )}
        </DialogContent>
        <Divider />
        <div className='row'>
          <Button
            disabled={loading}
            onClick={() => {
              onClose()
              setClientId('')
              setSecretId('')
              setToken('')
              setError(null)
            }}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              color: '#6123FF',
              borderColor: '#6123FF',
              flex: '1',
              margin: '24px 16px 24px 32px'
            }}
            variant={'outlined'}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              height: '50px',
              textTransform: 'none',
              borderRadius: '14px',
              backgroundColor: '#6123FF',
              flex: '1',
              margin: '24px 32px 24px 0px'
            }}
            variant={'contained'}
          >
            Connect
          </Button>
        </div>
      </Dialog>
      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        disabled={loading}
        open={successSnack}
        autoHideDuration={6000}
        onClose={() => setSuccessSnack(false)}
        message={'Successfully connected!'}
      />
    </>
  )
}

export default AdConnectDialog
