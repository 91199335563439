import React from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'

export default function FormStepper() {
  return (
    <Box>
      <Stepper
        orientation='vertical'
        sx={{
          width: '100%',
          alignItems: 'center',
          '& .MuiStepConnector-line': {
            borderColor: '#E7E8EA',
            paddingRight: '18px',
            height: 45
          }
        }}
      >
        <Step
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <Box
                sx={{
                  display: 'flex',
                  paddingLeft: '12px,',
                  width: 12,
                  height: 12,
                  backgroundColor: '#E7E7EE',
                  borderRadius: '50%'
                }}
              />
            )}
          ></StepLabel>
        </Step>
        <Step
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <Box
                sx={{
                  display: 'flex',
                  paddingLeft: '12px,',
                  width: 12,
                  height: 12,
                  backgroundColor: '#E7E7EE',
                  borderRadius: '50%'
                }}
              />
            )}
          ></StepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}
