import React from 'react'
import { Backdrop, Box, Paper, Button, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import CloseButton from './CloseButton'
import backGround from '../images/bg.svg'

export default function CustomizedDialogs({
  open,
  actionDescription,
  handleClose,
  title,
  children,
  modalWidth = '880px',
  headerColor = 'inherit',
  denseContent = false,
  actions
}) {
  return (
    <Backdrop
      sx={{
        backgroundImage: `url(${backGround})`,
        backgroundSize: 'cover',
        color: '#fff',
        zIndex: 30
      }}
      open={open}
    >
      <Paper
        open={open}
        sx={{
          maxHeight: '99vh',
          background: '#F8F8FB',
          overflow: 'auto',
          zIndex: 35,
          width: modalWidth,
          borderRadius: '24px'
        }}
        onClick={e => e.stopPropagation()}
      >
        <DialogTitle
          id='customized-dialog-title'
          sx={{
            background: headerColor,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Satoshi',
            fontWeight: 500,
            fontSize: 24
          }}
        >
          {title}
          <CloseButton onClick={handleClose} />
        </DialogTitle>

        <DialogContent
          sx={{
            padding: denseContent ? 0 : '16px'
          }}
        >
          {children}
        </DialogContent>
        <DialogActions sx={{ background: headerColor, padding: '16px 16px 16px' }}>
          <Box display={'flex'} width={'100%'} columnGap={2} justifyContent={'space-between'}>
            <Box width={'30%'}>{actionDescription}</Box>
            <Box width='100%' display={'flex'} columnGap={2} justifyContent={'flex-end'}>
              {actions?.map(
                (action, index) =>
                  !action.isHidden && (
                    <Button
                      key={index}
                      disabled={action.disabled}
                      sx={{
                        background: !action.disabled && action.variant === 'contained' ? '#6123FF' : 'none',
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        color: action.variant === 'contained' ? '#fff' : '#6123FF',
                        textTransform: 'none',
                        border: action.variant === 'outlined' ? '1px solid #E7E7E7' : 'none',
                        width: 200,
                        '&:hover': {
                          background: '#1565c0'
                        }
                      }}
                      autoFocus
                      onClick={action.handler}
                    >
                      {action.text}
                    </Button>
                  )
              )}
            </Box>
          </Box>
        </DialogActions>
      </Paper>
    </Backdrop>
  )
}
