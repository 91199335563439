export const timelineOptions = [
  {
    name: 'Immediately',
    value: 'IMMEDIATELY'
  },
  {
    name: 'Throughout the Day',
    value: 'THROUGHOUT_DAY'
  },
  {
    name: 'Throughout the Week',
    value: 'THROUGHOUT_WEEK'
  },
  {
    name: 'Throughout the Month',
    value: 'THROUGHOUT_MONTH'
  },
  {
    name: 'Throughout 3 Months',
    value: 'THROUGHOUT_3_MONTHS'
  },
  {
    name: 'Throughout 6 Months',
    value: 'THROUGHOUT_6_MONTHS'
  },
  {
    name: 'Throughout the Year',
    value: 'THROUGHOUT_YEAR'
  }
]

export const triggerCriteriaOptions = [
  {
    name: 'User did',
    value: 'USER_DID'
  },
  {
    name: 'User did not',
    value: 'USER_NOT_DID'
  }
]

export const triggerActionOptions = [
  {
    name: 'Link Clicked',
    value: 'CLICKED_LINK'
  },
  {
    name: 'Email Opened',
    value: 'OPENED_EMAIL'
  },
  {
    name: 'Attach Opened',
    value: 'OPENED_ATTACH'
  },
  {
    name: 'QR Scanned',
    value: 'SCANNED_QR_CODE'
  },
  {
    name: 'Creds Provided',
    value: 'PROVIDED_CREDENTIALS'
  },
  {
    name: 'Replied',
    value: 'REPLIED_TO_EMAIL'
  }
]
export const phoneNumbersOptions = [
  {
    name: '+12566266422',
    value: '+12566266422'
  },
  {
    name: '+48786412300',
    value: '+48786412300'
  }
]
export const messagesType = ['SMS', 'WHATSAPP']
