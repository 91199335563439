import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { styled } from '@mui/material/styles'
import { Button, Collapse, IconButton, ListItemText, Menu, MenuItem, Select, TextField } from '@mui/material'
import Spacer from 'src/common/components/Spacer'

import EditIcon from 'src/common/icons/editIcon'
// import { ValueAndUnit } from 'src/pages/overview'
import { toggleElement } from 'src/heplers'
import { SearchInput } from 'src/common/components/DataGrid'
import WorkflowActionSelect from 'src/common/components/WorkflowActionSelect'
import DeleteIcon from 'src/common/icons/deleteIcon'
import workflowsService from 'src/services/workflows.service'
import trainingsService from 'src/services/trainings.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
// import settingsService from 'src/services/settings.service'
import Highlighter from 'react-highlight-words'
import companyService from 'src/services/company.service'
import { ChooseUsersGroups } from 'src/common/components/ChooseUsersGroups'

export const TableBigNum = ({ color, num }) => (
  <span
    style={{
      fontFamily: 'Satoshi',
      fontSize: '24px',
      fontWeight: '500',
      color: color ? color : num < 50 && '#DB0F34'
    }}
  >
    {num ? num : '0'}
  </span>
)

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const Input = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))
const SelectStyled = styled(Select)(() => ({
  border: '1px solid #E7E7EE',
  borderRadius: 20,
  padding: '0px 16px',
  fontSize: '16px',
  backgroundColor: '#fff',
  '.MuiSelect-select': {
    padding: '13.5px 14px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const WorkflowCreate = ({ selectedId, isEdit = false, handleClose = () => {} }) => {
  const [searchTriggers, setSearchTriggers] = useState('')
  const [loading, setLoading] = useState(false)
  const [allTriggers, setAllTriggers] = useState([])
  const [allTrainings, setAllTrainings] = useState([])
  const [connectors, setConnectors] = useState([])
  const [errors, setErrors] = useState(null)

  const [openTrigger, setOpenTrigger] = useState(!isEdit)
  const [openActionsForm, setOpenActionsForm] = useState(false)
  const [openUsersStep, setOpenUsersStep] = useState(false)

  const [selectedTrigger, setSelectedTrigger] = useState(null)
  const [selectedActions, setSelectedActions] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const [sortBy, setSortBy] = useState(null)

  const handleSort = field => {
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }

  const transformActionsToEditable = actions => {
    return actions.map((el, index) => {
      return { ...el, value: 1, id: index + 1 }
    })
  }

  useEffect(() => {
    setLoading(true)
    if (selectedId && isEdit) {
      workflowsService.getWorkflow(selectedId).then(resp => {
        setLoading(false)
        setSelectedTrigger(resp.data.trigger.id)
        setSelectedActions(transformActionsToEditable(resp.data.actions))
        setSelectedGroups(resp.data.groupIds)
        setSelectedUsers(resp.data.userIds)
      })
    }

    Promise.all([trainingsService.getPackages(), workflowsService.getTriggers(), companyService.getCompanyConfigs()])
      .then(values => {
        const [packs, triggers, configs] = values
        setLoading(false)
        setAllTrainings(packs.data)
        setAllTriggers(triggers.data)
        setConnectors([configs.data.googleId ? 'GMAIL' : '', configs.data.microsoftId ? 'OUTLOOK' : ''])
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }, [])

  const handleSaveForm = () => {
    const data = {
      triggerId: selectedTrigger,
      actions: selectedActions,
      groupIds: selectedGroups,
      userIds: selectedUsers
    }

    if (isEdit) {
      workflowsService
        .updateWorkflow(selectedId, data)
        .then(() => {
          handleClose('updated')
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      workflowsService
        .addWorkflow(data)
        .then(() => {
          handleClose('created')
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  const ref = useRef(null)
  const addButtonRef = useRef(null)

  const scrollActions = () => {
    setTimeout(() => {
      addButtonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }, 20)

    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      })
    }, 60)
  }

  const handleActionSelect = opt => {
    const currentDate = new Date()
    const timestamp = currentDate.getTime()
    setSelectedActions([...selectedActions, { id: timestamp, type: opt, value: null }])
    scrollActions()
  }

  const handleRemoveOption = id => {
    setSelectedActions(selectedActions.filter(el => el.id !== id))
  }

  const handleSearchTriggers = e => {
    setSearchTriggers(e.target.value)
  }
  const handleMainNextStep = () => {
    setOpenTrigger(false)
    setOpenActionsForm(true)
  }

  const handleEditMainInfo = () => {
    setOpenTrigger(true)
    setOpenActionsForm(false)
  }
  const handleEditModules = () => {
    setOpenTrigger(false)
    setOpenActionsForm(true)
    setOpenUsersStep(false)
  }
  const handleEditGroups = () => {
    setOpenTrigger(false)
    setOpenActionsForm(false)
    setOpenUsersStep(true)
  }

  const handleModulesPrevStep = () => {
    setOpenTrigger(true)
    setOpenActionsForm(false)
  }
  const handleModulesNextStep = () => {
    setOpenTrigger(false)
    setOpenActionsForm(false)
    setOpenUsersStep(true)
  }
  const handleGroupsPrevStep = () => {
    setOpenActionsForm(true)
    setOpenUsersStep(false)
  }
  const handleGroupsNextStep = () => {
    setOpenUsersStep(false)
  }

  const handleGroupSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = selectedGroups
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setSelectedGroups(newItems)
    } else {
      setSelectedGroups(toggleElement(selectedGroups, id))
    }
  }
  const handleUserSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = selectedUsers
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setSelectedUsers(newItems)
    } else {
      setSelectedUsers(toggleElement(selectedUsers, id))
    }
  }

  const groupsFormInvalid = !selectedGroups?.length && !selectedUsers?.length

  const actionOptions = {
    WEBHOOK: { name: 'Send Webhook' },
    TRAINING_PACKAGE: { name: 'Enroll to Training Package' },
    NOTIFICATION: { name: 'Send notification' },
    SLACK: { name: 'Send training in Slack' }
  }

  return (
    <>
      <div style={{ height: 'calc(100vh - 110px)', overflowY: 'auto' }}>
        <div className='column' style={{ padding: '32px' }}>
          <div className='row' style={{ width: '900px' }}>
            {/* <Button startIcon={<BackIcon />} >Back to Create New Package</Button> */}
          </div>
          <p className='drawer-title'>{isEdit ? 'Edit' : 'Create new'} workflow</p>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Collapse
                sx={{
                  background: '#fff',
                  borderRadius: '10px',
                  padding: '12px 32px 32px 32px',
                  marginBottom: '16px'
                }}
                in={openTrigger}
                collapsedSize={75}
              >
                <div className='column'>
                  {!openTrigger && (
                    <div
                      className='row row-center'
                      style={{
                        height: '60px',
                        padding: '10px 0px'
                      }}
                    >
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '24px',
                          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '16px'
                        }}
                      >
                        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>

                      <p style={{ maxWidth: 400 }}>
                        Trigger: <strong>{allTriggers.find(el => el.id == selectedTrigger)?.name}</strong>
                      </p>

                      <Spacer />
                      <div className='row row-center'>
                        <div className='row row-center'>
                          {allTriggers.find(el => el.id == selectedTrigger)?.service == 'CYBERLIFT' && (
                            <img src={'images/wf3.png'} width={28} alt='' />
                          )}
                          {allTriggers.find(el => el.id == selectedTrigger)?.service == 'GMAIL' && (
                            <img src={'images/wf5.png'} width={28} alt='' />
                          )}
                          {allTriggers.find(el => el.id == selectedTrigger)?.service == 'OUTLOOK' && (
                            <img src={'images/wf6.png'} width={28} alt='' />
                          )}
                          {allTriggers.find(el => el.id == selectedTrigger)?.service == 'HAVE_I_BEEN_PWNED' && (
                            <img src={'images/wf4.png'} width={28} alt='' />
                          )}
                          <p
                            style={{
                              fontSize: 13,
                              color: '#76767F',
                              marginLeft: 12,
                              textTransform: 'capitalize',
                              marginRight: 20
                            }}
                          >
                            {allTriggers
                              .find(el => el.id == selectedTrigger)
                              ?.service.replaceAll('_', ' ')
                              .toLowerCase()}
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          variant='outlined'
                          onClick={handleEditMainInfo}
                          sx={{
                            borderColor: '#E7E7EE',
                            borderRadius: '12px',
                            color: '#6123FF',
                            textTransform: 'none',
                            flex: '1',
                            mr: '12px'
                          }}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  )}

                  <p className='drawer-subtitle'>Trigger</p>

                  <SearchInput
                    sx={{
                      margin: '0',
                      width: '100%',
                      '& .MuiInputBase-input': {
                        padding: '9px 14px'
                      }
                    }}
                    placeholder='Search'
                    fullWidth={true}
                    value={searchTriggers}
                    onChange={handleSearchTriggers}
                  ></SearchInput>

                  <div style={{ marginBottom: 20 }} className='row row-center groups-list-heading'>
                    <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'70%'} />
                    <SortHeader name='Service' sort={sortBy} onSort={handleSort} width={'15%'} />
                  </div>

                  <div
                    style={{
                      maxHeight: 'calc(100vh - 530px)',
                      overflow: 'auto'
                    }}
                  >
                    {allTriggers
                      .map(trigger => ({
                        ...trigger,
                        active: ['HAVE_I_BEEN_PWNED', 'CYBERLIFT', ...connectors].includes(trigger.service)
                      }))
                      .sort((a, b) => {
                        if (!sortBy) {
                          return 0
                        } else {
                          let fa = a[sortBy.name.toLowerCase()].toLowerCase(),
                            fb = b[sortBy.name.toLowerCase()].toLowerCase()

                          if (fa < fb) {
                            return sortBy.asc ? -1 : 1
                          }
                          if (fa > fb) {
                            return sortBy.asc ? 1 : -1
                          }
                          return 0
                        }
                      })
                      .sort((a, b) => Number(b.active) - Number(a.active))
                      .filter(
                        t =>
                          t.name.toLowerCase().includes(searchTriggers.toLocaleLowerCase()) ||
                          t.service.toLowerCase().includes(searchTriggers.toLocaleLowerCase()) ||
                          t.type.toLowerCase().includes(searchTriggers.toLocaleLowerCase())
                      )
                      .map(el => (
                        <div
                          key={el.id}
                          onClick={el.active ? () => setSelectedTrigger(el.id) : () => {}}
                          style={{
                            cursor: 'pointer'
                          }}
                        >
                          <div
                            style={{
                              opacity: el.active ? 1 : 0.6
                            }}
                            className='row'
                          >
                            <div
                              style={{
                                marginRight: '8px'
                              }}
                            >
                              {el.id == selectedTrigger ? (
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 20 20'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle cx='10.0004' cy='10' r='6.36364' fill='#6123FF' />
                                  <circle cx='10' cy='10' r='9.5' fill='white' stroke='#6123FF' />
                                  <circle cx='10' cy='10' r='6' fill='#6123FF' />
                                </svg>
                              ) : (
                                <svg
                                  width='20'
                                  height='20'
                                  viewBox='0 0 20 20'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle cx='10' cy='10' r='9.5' fill='white' stroke='#A3A4AB' />
                                </svg>
                              )}
                            </div>
                            <div
                              style={{
                                width: '70%'
                              }}
                              className='column'
                            >
                              <p
                                style={{
                                  fontSize: 13,
                                  color: '#76767F',
                                  textTransform: 'capitalize'
                                }}
                              >
                                <Highlighter
                                  highlightClassName='highlight-text'
                                  searchWords={[searchTriggers]}
                                  autoEscape={true}
                                  textToHighlight={el.type.toLowerCase().replace('_', ' ')}
                                />
                              </p>
                              <p
                                style={{
                                  fontWeight: 600
                                }}
                              >
                                <Highlighter
                                  highlightClassName='highlight-text'
                                  searchWords={[searchTriggers]}
                                  autoEscape={true}
                                  textToHighlight={el.name}
                                />
                              </p>
                            </div>
                            <div className='row row-center'>
                              {el.service == 'CYBERLIFT' && (
                                <img
                                  style={{
                                    filter: el.active ? 'grayscale(0)' : 'grayscale(1)'
                                  }}
                                  src={'images/wf3.png'}
                                  width={28}
                                  alt=''
                                />
                              )}
                              {el.service == 'GMAIL' && (
                                <img
                                  style={{
                                    filter: el.active ? 'grayscale(0)' : 'grayscale(1)'
                                  }}
                                  src={'images/wf5.png'}
                                  width={28}
                                  alt=''
                                />
                              )}
                              {el.service == 'OUTLOOK' && (
                                <img
                                  style={{
                                    filter: el.active ? 'grayscale(0)' : 'grayscale(1)'
                                  }}
                                  src={'images/wf6.png'}
                                  width={28}
                                  alt=''
                                />
                              )}
                              {el.service == 'HAVE_I_BEEN_PWNED' && (
                                <img
                                  style={{
                                    filter: el.active ? 'grayscale(0)' : 'grayscale(1)'
                                  }}
                                  src={'images/wf4.png'}
                                  width={28}
                                  alt=''
                                />
                              )}
                              <p
                                style={{
                                  fontSize: 13,
                                  color: '#76767F',
                                  marginLeft: 12,
                                  textTransform: 'capitalize'
                                }}
                              >
                                <Highlighter
                                  highlightClassName='highlight-text'
                                  searchWords={[searchTriggers]}
                                  autoEscape={true}
                                  textToHighlight={el.service.replaceAll('_', ' ').toLowerCase()}
                                />
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              height: '1px',
                              width: '100%',
                              background: '#E7E7EE',
                              margin: '20px 0px'
                            }}
                          ></div>
                        </div>
                      ))}
                  </div>

                  <div className='row' style={{ justifyContent: 'flex-end' }}>
                    <Button
                      disabled={selectedTrigger === null}
                      variant={'contained'}
                      onClick={handleMainNextStep}
                      sx={{
                        background: '#6123FF',
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#fff',
                        textTransform: 'none',
                        width: 270
                      }}
                    >
                      Next Step
                    </Button>
                  </div>
                </div>
              </Collapse>

              <Collapse
                sx={{
                  background: '#fff',
                  borderRadius: '10px',
                  padding: '12px 32px 32px 32px',
                  marginBottom: '16px'
                }}
                in={openActionsForm}
                collapsedSize={70}
              >
                {!openActionsForm && !selectedActions.length && (
                  <>
                    <div className='row row-center'>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '24px',
                          background: '#6123FF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '16px',
                          color: '#fff',
                          fontWeight: '600'
                        }}
                      >
                        2
                      </div>
                      <p className='drawer-subtitle'>Action</p>
                    </div>
                  </>
                )}
                {!openActionsForm && selectedActions.length && (
                  <>
                    <div className='row row-center' style={{ marginTop: 6 }}>
                      <div style={{ width: '430px' }} className='row row-center'>
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '24px',
                            background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '16px',
                            flexShrink: 0
                          }}
                        >
                          <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                              stroke='white'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <p style={{ maxWidth: 400 }} className=''>
                          Actions:{' '}
                          <strong>
                            {selectedActions
                              .map(el =>
                                el.type == 'WEBHOOK'
                                  ? 'Send webhook'
                                  : el.type == 'TRAINING_PACKAGE'
                                    ? 'Enroll to Training Package'
                                    : el.type == 'SLACK'
                                      ? 'Send training in Slack'
                                      : 'Send notification'
                              )
                              .join(', ')}
                          </strong>
                        </p>
                      </div>
                      <Spacer />

                      <div>
                        <Button
                          variant='outlined'
                          onClick={handleEditModules}
                          sx={{
                            borderColor: '#E7E7EE',
                            borderRadius: '12px',
                            color: '#6123FF',
                            textTransform: 'none',
                            flex: '1',
                            mr: '12px'
                          }}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                <div className='column'>
                  <p className='drawer-subtitle'>Action</p>
                  <div
                    style={{
                      maxHeight: '500px',
                      overflow: 'auto'
                    }}
                  >
                    {openActionsForm && (
                      <div>
                        {selectedActions.length > 0 &&
                          selectedActions.map(el => (
                            <div
                              key={el.id}
                              style={{
                                width: '100%'
                              }}
                              className='row'
                            >
                              <div className='column'>
                                <svg
                                  style={{
                                    marginTop: 16
                                  }}
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle cx='12' cy='12' r='11' stroke='#E7E7EE' strokeWidth='2' />
                                </svg>
                                <div
                                  style={{
                                    width: '2px',
                                    height: '100px',
                                    backgroundColor: '#E7E7EE',
                                    margin: '10px 30px 0px 11px'
                                  }}
                                ></div>
                              </div>

                              <div
                                key={el.id}
                                className='selected-action column'
                                style={{
                                  flexGrow: 1
                                }}
                              >
                                <div className='row row-center mb20'>
                                  <p
                                    style={{
                                      fontWeight: 600
                                    }}
                                  >
                                    {actionOptions[el.type].name}
                                  </p>
                                  <Spacer />
                                  <div className='romove-button'>
                                    <IconButton onClick={() => handleRemoveOption(el.id)}>
                                      <DeleteIcon color={'#8D8D94'} />
                                    </IconButton>
                                  </div>
                                </div>

                                {el.type == 'WEBHOOK' && (
                                  <div
                                    className='row'
                                    style={{
                                      flexGrow: 1
                                    }}
                                  >
                                    <Input
                                      error={errors?.name}
                                      fullWidth
                                      sx={{
                                        borderRadius: '20px',
                                        mb: '12px',
                                        backgroundColor: '#fff',
                                        padding: '5px 16px',
                                        '.MuiFormLabel-root': {
                                          top: 0
                                        }
                                      }}
                                      label='Webhook URL'
                                      variant='standard'
                                      value={el?.container?.url || ''}
                                      onChange={e => {
                                        setSelectedActions(
                                          selectedActions.map(action =>
                                            action.id == el.id
                                              ? {
                                                  ...action,
                                                  container: {
                                                    url: e.target.value
                                                  },
                                                  value: e.target.value
                                                }
                                              : action
                                          )
                                        )
                                        setErrors(null)
                                      }}
                                    />
                                  </div>
                                )}

                                {el.type == 'TRAINING_PACKAGE' && (
                                  <SelectStyled value={el?.container?.trainingPackageId}>
                                    {allTrainings.map(training => (
                                      <MenuItem
                                        onClick={() => {
                                          setSelectedActions(
                                            selectedActions.map(action =>
                                              action.id == el.id
                                                ? {
                                                    ...action,
                                                    container: {
                                                      trainingPackageId: training.id
                                                    },
                                                    value: training.id
                                                  }
                                                : action
                                            )
                                          )
                                        }}
                                        key={training.id}
                                        value={training.id}
                                      >
                                        <ListItemText primary={training.name} />
                                      </MenuItem>
                                    ))}
                                  </SelectStyled>
                                )}

                                {el.type == 'SLACK' && (
                                  <SelectStyled value={el?.container?.slackAction}>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedActions(
                                          selectedActions.map(action =>
                                            action.id == el.id
                                              ? {
                                                  ...action,
                                                  container: {
                                                    slackAction: 'AI_TRAINING'
                                                  },
                                                  value: 'AI_TRAINING'
                                                }
                                              : action
                                          )
                                        )
                                      }}
                                      value={'AI_TRAINING'}
                                    >
                                      <ListItemText primary={'AI Training (Generate training using Cyberlift AI)'} />
                                    </MenuItem>
                                    <MenuItem
                                      disabled={true}
                                      onClick={() => {
                                        setSelectedActions(
                                          selectedActions.map(action =>
                                            action.id == el.id
                                              ? {
                                                  ...action,
                                                  container: {
                                                    slackAction: 'AI_NOTIFICATION'
                                                  },
                                                  value: 'AI_NOTIFICATION'
                                                }
                                              : action
                                          )
                                        )
                                      }}
                                      value={'AI_NOTIFICATION'}
                                    >
                                      <ListItemText
                                        primary={'AI Notification (Generate notification using Cyberlift AI)'}
                                      />
                                    </MenuItem>
                                  </SelectStyled>
                                )}

                                {el.type == 'NOTIFICATION' && (
                                  <div className='column'>
                                    <Input
                                      error={errors?.name}
                                      sx={{
                                        borderRadius: '20px 20px 0px 0px',
                                        mb: '-1px',
                                        backgroundColor: '#fff',
                                        padding: '5px 16px',
                                        '.MuiFormLabel-root': {
                                          top: 0
                                        }
                                      }}
                                      label='Receivers emails'
                                      variant='standard'
                                      value={el?.container?.emails || ''}
                                      onChange={e => {
                                        // setForm({ ...form, emails: e.target.value })
                                        setSelectedActions(
                                          selectedActions.map(action =>
                                            action.id == el.id
                                              ? {
                                                  ...action,
                                                  container: {
                                                    ...action.container,
                                                    emails: e.target.value
                                                  },
                                                  value: e.target.value && action.container?.body ? 1 : 0
                                                }
                                              : action
                                          )
                                        )
                                        setErrors(null)
                                      }}
                                    />
                                    <Input
                                      error={errors?.name}
                                      sx={{
                                        borderRadius: '0px 0px 20px 20px',
                                        mb: '12px',
                                        backgroundColor: '#fff',
                                        padding: '5px 16px',
                                        '.MuiFormLabel-root': {
                                          top: 0
                                        }
                                      }}
                                      multiline={true}
                                      label='Message'
                                      variant='standard'
                                      value={el?.container?.body || ''}
                                      onChange={e => {
                                        // setForm({ ...form, message: e.target.value })
                                        setSelectedActions(
                                          selectedActions.map(action =>
                                            action.id == el.id
                                              ? {
                                                  ...action,
                                                  container: {
                                                    ...action.container,
                                                    body: e.target.value
                                                  },
                                                  value: e.target.value && action.container?.emails ? 1 : 0
                                                }
                                              : action
                                          )
                                        )
                                        setErrors(null)
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        <div ref={addButtonRef}>
                          <WorkflowActionSelect onSelectOption={handleActionSelect} />
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      height: '1px',
                      width: '100%',
                      background: '#F1F1F5',
                      margin: '20px 0px'
                    }}
                  ></div>

                  <div ref={ref} className='row row-center'>
                    <Button
                      variant={'outlined'}
                      onClick={handleModulesPrevStep}
                      sx={{
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        textTransform: 'none',
                        width: 270
                      }}
                    >
                      Previous Step
                    </Button>
                    <Spacer />
                    <Button
                      disabled={selectedActions.length == 0 || selectedActions.findIndex(el => !el.value) !== -1}
                      variant={'contained'}
                      onClick={handleModulesNextStep}
                      sx={{
                        background: '#6123FF',
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#fff',
                        textTransform: 'none',
                        width: 270
                      }}
                    >
                      Next Step
                    </Button>
                  </div>
                </div>
              </Collapse>

              <Collapse
                sx={{
                  background: '#fff',
                  borderRadius: '10px',
                  padding: '12px 32px 32px 32px'
                }}
                in={openUsersStep}
                collapsedSize={70}
              >
                {!openUsersStep && groupsFormInvalid && (
                  <>
                    <div className='row row-center' style={{ marginTop: 6 }}>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          borderRadius: '24px',
                          background: '#6123FF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '16px',
                          color: '#fff',
                          fontWeight: '600'
                        }}
                      >
                        3
                      </div>
                      <p className='drawer-subtitle'>Groups/Users</p>
                    </div>
                  </>
                )}
                {!openUsersStep && !groupsFormInvalid && (
                  <>
                    <div className='row row-center'>
                      <div style={{ width: '430px' }} className='row row-center'>
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '24px',
                            background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '16px',
                            flexShrink: 0
                          }}
                        >
                          <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                              stroke='white'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        {/* <p className=''>Users/Groups: <strong>{selectedUsers.length + selectedGroups.length} selected</strong></p> */}
                        <p className=''>
                          {selectedUsers.length !== 0 && (
                            <>
                              Users: <strong>{selectedUsers.length} selected</strong>
                            </>
                          )}
                          {selectedUsers.length !== 0 && selectedGroups.length !== 0 && <>,</>}
                          {selectedGroups.length !== 0 && (
                            <>
                              {' '}
                              Groups: <strong>{selectedGroups.length} selected</strong>
                            </>
                          )}
                        </p>
                      </div>
                      <div>{/* <ValueAndUnit val={form.groups.length} unit={'Includes'} /> */}</div>
                      <Spacer />

                      <div>
                        <Button
                          variant='outlined'
                          onClick={handleEditGroups}
                          sx={{
                            borderColor: '#E7E7EE',
                            borderRadius: '12px',
                            color: '#6123FF',
                            textTransform: 'none',
                            flex: '1',
                            mr: '12px'
                          }}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                <div className='column'>
                  <ChooseUsersGroups
                    selectedGroups={selectedGroups}
                    selectedUsers={selectedUsers}
                    onSelectGroup={handleGroupSelect}
                    onSelectUser={handleUserSelect}
                  />

                  <div
                    style={{
                      height: '1px',
                      width: '100%',
                      background: '#F1F1F5',
                      margin: '20px 0px'
                    }}
                  ></div>

                  <div className='row row-center'>
                    <Button
                      variant={'outlined'}
                      onClick={handleGroupsPrevStep}
                      sx={{
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        textTransform: 'none',
                        width: 270
                      }}
                    >
                      Previous Step
                    </Button>
                    <Spacer />
                    <Button
                      disabled={groupsFormInvalid}
                      variant={'contained'}
                      onClick={handleGroupsNextStep}
                      sx={{
                        background: '#6123FF',
                        borderRadius: '12px',
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#fff',
                        textTransform: 'none',
                        width: 270
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Collapse>
            </>
          )}
        </div>
      </div>

      <div
        style={{
          bottom: '0',
          right: '0',
          background: '#fff',
          padding: '25px 32px'
        }}
        className='row'
      >
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <Button
            sx={{
              color: '#6123FF',
              borderColor: '#E7E7EE',
              textTransform: 'none',
              height: '60px',
              width: '100%',
              borderRadius: '14px'
            }}
            onClick={() => handleClose()}
            variant={'outlined'}
          >
            Cancel
          </Button>
        </div>
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <Button
            variant='contained'
            sx={{
              color: '#fff',
              backgroundColor: '#6123FF',
              textTransform: 'none',
              height: '60px',
              width: '100%',
              borderRadius: '14px',
              '&:hover': { backgroundColor: '#7843ff' }
            }}
            disabled={
              !selectedTrigger ||
              selectedActions.length == 0 ||
              (selectedGroups.length == 0 && selectedUsers.length == 0)
            }
            onClick={() => handleSaveForm()}
          >
            {isEdit ? 'Save' : 'Create'} Workflow
          </Button>
        </div>
      </div>
    </>
  )
}

export default WorkflowCreate
