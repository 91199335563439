import React, { useState, useContext, useRef } from "react";
import { Button, Collapse, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

import LoadingIndicator from "src/common/components/LoadingIndicator";
import settingsService from "src/services/settings.service";

import { SnackBarContext } from "src/App";

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

}))


const OktaConnectDialog = ({ open, onClose, onSuccess }) => {
  const [clientId, setClientId] = useState('')
  const [secretId, setSecretId] = useState('')
  const [group, setGroup] = useState('')
  const [domain, setDomain] = useState('')

  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [successSnack, setSuccessSnack] = useState(null)

  const { setSuccessMessage, setErrorMessage } = useContext(SnackBarContext)

  const [loading, setLoading] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(false)

  const groupsRef = useRef(null)
  const groupsInputRef = useRef(null)

  const clearForm = () => {
    onClose()
    setClientId('')
    setSecretId('')
    setDomain('')
    setGroup('')
    setError(null)
    setErrors({})
  }

  const collapseGroups = () => {
    if (openCollapse) {
      setOpenCollapse(!openCollapse)

    } else {
      setOpenCollapse(!openCollapse)
      setTimeout(() => {
        groupsRef.current.scrollIntoView({ behavior: "smooth" })
        groupsInputRef.current.focus()
      }, 300);

    }
  }

  const handleSave = () => {
    if (!domain || !clientId || !secretId) {
      let err = {}
      if (!domain) {
        err = { ...err, domain: 'Domain is required' }
      }
      if (!clientId) {
        err = { ...err, clientId: 'ClientId is required' }
      }
      if (!secretId) {
        err = { ...err, secretId: 'Client Secret is required' }
      }
      setErrors(err)
      return;
    }

    localStorage.setItem('okta-domain', domain)
    setLoading(true)
    settingsService.connectOkta(domain, clientId, secretId, group).then(resp => {
      setLoading(false)
      if (resp.data == 'CONNECTION_FAILURE') {
        setError('Connection failure')
        setErrorMessage('Connection failure')
      }
      if (resp.data == 'SUCCESSFULLY_CONNECTED') {
        setSuccessMessage('Successfully connected')
        onClose()
        onSuccess()
      }

    }).catch(() => {
      setLoading(false)
      setError('Something went wrong')
    })
  }

  return (<>
    <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '22px', maxWidth: '750px', width: '100%' } }} open={open} onClose={() => {
      onClose()
      clearForm()
    }}>
      <DialogTitle sx={{
        fontFamily: 'Satoshi',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '35px',
        lineHeight: '110%',
        color: '#1B1B26',
      }}>Okta integrations</DialogTitle>
      <DialogContent >
        {loading && <div style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: '#00000030',
          zIndex: '2',
        }} className="column column-center">
          <LoadingIndicator text={'Please wait'} />
        </div>}
        <div style={{ border: '1px solid #e0e0e0', borderRadius: '14px', background: '#fafafa', padding: '20px', marginBottom: '15px' }}>
          <p style={{ fontWeight: 900, fontSize: 18, marginBottom: '10px' }}>Authorize Cyberlift</p>
          <p>You need to authorize Cyberlift on Okta to be able to generate OAuth credentials.</p>
          <Button onClick={() => window.open("https://helpcenter.cyberlift.ai/content/cyberlift-okta-onboarding-guide", "_blank")} variant="outlined" sx={{ borderRadius: '14px', color: '#6123FF', borderColor: '#6123FF', textTransform: 'none', margin: '15px 0px 0px 0px' }}>Authorize Cyberlift on Okta</Button>

        </div>
        <div style={{ border: '1px solid #e0e0e0', borderRadius: '14px', background: '#fafafa', padding: '20px' }}>
          <p style={{ fontWeight: 900, fontSize: 18, marginBottom: '10px' }}>Okta Credentials</p>
          <p style={{ marginBottom: 24 }}>Please provide your Okta details below</p>

          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px', '& .MuiInput-root': { maxWidth: '300px' } }}
            label='Domain (ex. https://cyberlift.okta.com)'
            type="text"
            variant='standard'
            value={domain}
            error={errors.domain}
            onChange={(e) => {
              setDomain(e.target.value)
              setErrors({ ...errors, domain: null })
            }}
          />
          {(errors.domain) &&
            <p style={{ marginTop: '-10px', marginBottom: '10px', fontSize: '14px' }} className='error-text'>{errors.domain}</p>
          }

          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client ID'
            type="text"
            variant='standard'
            value={clientId}
            error={errors.clientId}
            onChange={(e) => {
              setClientId(e.target.value)
              setErrors({ ...errors, clientId: null })
            }}
          />
          {(errors.clientId) &&
            <p style={{ marginTop: '-10px', marginBottom: '10px', fontSize: '14px' }} className='error-text'>{errors.clientId}</p>
          }

          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '16px' }}
            label='Client Secret'
            type="text"
            variant='standard'
            value={secretId}
            error={errors.secretId}
            onChange={(e) => {
              setSecretId(e.target.value)
              setErrors({ ...errors, secretId: null })
            }}
          />
          {(errors.secretId) &&
            <p style={{ marginTop: '-10px', marginBottom: '10px', fontSize: '14px' }} className='error-text'>{errors.secretId}</p>
          }


          {(error) &&
            <p style={{ marginTop: '-10px', marginBottom: '10px', fontSize: '14px' }} className='error-text'>{error}</p>
          }

          <Collapse
            in={openCollapse}
            collapsedSize={45}

          >
            <div
              className="row row-center"
              style={{ width: '100%', margin: '20px 0px', cursor: 'pointer' }}
              onClick={() => {
                collapseGroups()
              }}>

              <p>Sync specific group</p>
              <div style={{ margin: '0px 10px', height: '1px', flex: '1', background: '#bebebe' }}></div>
              <svg style={{ transition: '0.4s', transform: openCollapse && 'rotate(180deg)' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 5.33337L8 10.3334L13 5.33337" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <InputText
              ref={groupsRef}
              inputRef={groupsInputRef}
              fullWidth
              sx={{ borderRadius: '20px', mb: '16px' }}
              label='Group (eg. my-group)'
              type="text"
              variant='standard'
              value={group}
              error={error}
              onChange={(e) => {
                setGroup(e.target.value)
                setError(null)
              }}
            />
          </Collapse>
        </div>


      </DialogContent>
      <Divider />
      <div className='row'>
        <Button
          disabled={loading}
          onClick={() => {
            clearForm()
          }}
          sx={{ height: '50px', textTransform: 'none', borderRadius: '14px', color: '#6123FF', borderColor: '#6123FF', flex: '1', margin: '24px 16px 24px 32px' }}
          variant={'outlined'}
        >Cancel</Button>
        <Button onClick={handleSave} sx={{ height: '50px', textTransform: 'none', borderRadius: '14px', backgroundColor: '#6123FF', flex: '1', margin: '24px 32px 24px 0px' }} variant={'contained'} >Connect</Button>
      </div>
    </Dialog>
    <Snackbar
      sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disabled={loading}
      open={successSnack}
      autoHideDuration={6000}
      onClose={() => setSuccessSnack(false)}
      message={'Successfully connected!'}
    />
  </>
  )
}

export default OktaConnectDialog