import React, { useState } from 'react'
import TextField from '@mui/material/TextField'

import { styled } from '@mui/material/styles'
import { Button, Divider } from '@mui/material'

import './styles.css'

import CustomCheckbox from '../CustomCheckbox'
import { useEffect } from 'react'
import useDebounce from 'src/hooks'
import LoadingIndicator from '../LoadingIndicator'

export const SearchInput = styled(TextField)((width = 640) => ({
  width: width,
  marginTop: '20px',
  marginLeft: '20px',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    border: '1px solid #E7E7EE',
    background: '#fff',
    width: width
  }
}))

const SortIcon = ({ direction = 'asc', sort }) => {
  return direction == 'asc' ? (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.7915 16.0415V4.1665'
        strokeWidth={'1.5'}
        stroke='#76767F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.1665 9.7915L9.7915 4.1665L15.4165 9.7915'
        stroke={sort == 'asc' ? '#6123FF' : '#76767F'}
        strokeLinecap='round'
        strokeWidth={'1.5'}
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.7915 4.1665V16.0415'
        stroke={sort == 'desc' ? '#6123FF' : '#76767F'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.1665 10.4165L9.7915 16.0415L15.4165 10.4165'
        stroke={sort == 'desc' ? '#6123FF' : '#76767F'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TableHeaderButton = ({ total, col, sorting, sortBy, onFilter = () => { }, onSort = () => { }, filters }) => {
  const [open, setOpen] = useState(false)
  const [sortDirection, setSortDirection] = useState(sorting || null)

  const [selectedFilters, setSelectedFilters] = useState([])

  useEffect(() => {
    if (sortDirection) {
      onSort({ field: col.field, direction: sortDirection })
    }
  }, [sortDirection])

  const handleSort = sort => {
    setSortDirection(sort)
  }

  useEffect(() => {
    //TODO: refactor global filters

    if (col.headerName == 'Status') {
      if (filters.status) {
        setSelectedFilters(filters.status?.split(',').map(el => capitalizeFirstLetter(el.toLowerCase())))
      } else {
        setSelectedFilters([])
      }
    }

  }, [filters])




  const handleSelectFilter = filter => {
    setSelectedFilters(
      selectedFilters.includes(filter) ? selectedFilters.filter(el => el !== filter) : [...selectedFilters, filter]
    )
    onFilter({
      [col.field]: selectedFilters.includes(filter)
        ? selectedFilters
            .filter(el => el !== filter)
            .map(el => (['status', 'level'].includes(col.field) ? el : el.toLowerCase()))
            .join(',')
        : [...selectedFilters, filter]
            .map(el => (['status', 'level'].includes(col.field) ? el : el.toLowerCase()))
            .join(',')
    })
  }
  return (
    <div style={{ position: 'relative' }}>
      <div
        onClick={() => setOpen(!open)}
        className={`row row-center table-header-cell-btn
          ${open && 'table-header-cell-btn-open'}
          ${((sortDirection && sortBy.field == col.field) || selectedFilters.length) && 'table-header-cell-btn-active'}`}
      >
        {sortDirection && sortBy.field == col.field && (
          <div className='row row-center' style={{ marginRight: '10px' }}>
            <SortIcon direction={sortDirection} sort={sortDirection} />
          </div>
        )}
        <span> {selectedFilters.length !== 1 ? col.headerName : selectedFilters[0]}</span>
        {col.total && <div className='table-header-cell-chip'>{total}</div>}
        {selectedFilters.length == 1 && (
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16.875 5.625L8.125 14.3746L3.75 10'
              stroke='#6123FF'
              strokeWidth='1.875'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
        {selectedFilters.length > 1 && (
          <div className='table-header-cell-filercount row row-center'>{selectedFilters.length}</div>
        )}{' '}
        {selectedFilters.length == 0 && (
          <svg
            className='open-ico'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M3 5.33334L8 10.3333L13 5.33334' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        )}
      </div>

      {open && (
        <>
          <div className='popup-overlay' onClick={() => setOpen(false)}></div>

          <div style={col.isLast && { right: '0px', left: 'unset' }} className='table-header-cell-opions'>
            <div className='column'>
              <div
                onClick={() => {
                  handleSort(sortDirection == 'asc' ? null : 'asc')
                }}
                className='row row-center table-header-cell-opion'
              >
                <SortIcon direction={'asc'} sort={sortBy.field == col.field && sortDirection ? sortDirection : null} />

                <span
                  style={{
                    marginLeft: '8px',
                    color: sortBy.field == col.field && sortDirection == 'asc' && '#1B1B26'
                  }}
                >
                  Sort ascending
                </span>
              </div>
            </div>
            <div
              onClick={() => {
                handleSort(sortDirection == 'desc' ? null : 'desc')
              }}
              className='row row-center table-header-cell-opion'
            >
              <SortIcon direction={'desc'} sort={sortDirection} />

              <span
                style={{
                  marginLeft: '8px',
                  color: sortBy.field == col.field && sortDirection == 'desc' && '#1B1B26'
                }}
              >
                Sort descending
              </span>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {col?.filterOptions && (
                <>
                  <Divider />
                </>
              )}
              {col?.filterOptions &&
                col?.filterOptions.map(el => (
                  <div
                    key={el}
                    onClick={() => handleSelectFilter(el)}
                    style={{}}
                    className='row row-center table-header-cell-opion'
                  >
                    <CustomCheckbox checked={selectedFilters.includes(el)} sx={{ padding: '0px', pr: '8px' }} />
                    <span
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >
                      {el}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const Pagination = ({ page, total, onChange }) => {
  const [open, setOpen] = useState(false)
  const pages = []

  for (var i = 1; i <= total; i++) {
    pages.push(i)
  }
  return (
    <div style={{ cursor: 'pointer', position: 'relative' }}>
      <div onClick={() => setOpen(!open)} className='row row-center'>
        <p
          style={{
            color: '#76767F',
            fontSize: '13px',
            marginRight: '5px'
          }}
        >
          Page {page} of {total}
        </p>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M3 5.33337L8 10.3334L13 5.33337' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </div>
      {open && (
        <>
          <div className='popup-overlay' onClick={() => setOpen(false)}></div>
          <div className='poagintion-popup' style={{}}>
            {pages.map(el => (
              <div
                key={el}
                className={`poagintion-popup-el ${el == page && 'poagintion-popup-el-active'}`}
                onClick={() => {
                  onChange(parseInt(el))
                  setOpen(false)
                }}
              >
                {el}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
const RowsPerPage = ({ perPage, onChange }) => {
  const [open, setOpen] = useState(false)
  const options = [50, 150, 200, 250]
  return (
    <div style={{ cursor: 'pointer', position: 'relative' }}>
      <div onClick={() => setOpen(!open)} className='row row-center'>
        <p
          style={{
            color: '#76767F',
            fontSize: '13px',
            marginRight: '5px'
          }}
        >
          Rows per page: {perPage}
        </p>
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M3 5.33337L8 10.3334L13 5.33337' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </div>
      {open && (
        <div className='poagintion-popup' style={{}}>
          {options.map(el => (
            <div
              key={el}
              className={`poagintion-popup-el ${perPage == el && 'poagintion-popup-el-active'}`}
              onClick={() => {
                onChange(parseInt(el))
                setOpen(false)
              }}
            >
              {el}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function isSingle(number) {
  const numberStr = number.toString()
  const lastDigit = numberStr.slice(-1)
  const lastTwoDigits = numberStr.slice(-2)

  return lastDigit === '1' && lastTwoDigits !== '11'
}

const DataGrid = ({
  loading = false,
  total,
  rows,
  columns,
  actions,
  bulkActions,
  onSelect,
  selectedIds = [],
  onOpenElement,
  onFilter = () => {},
  emptyListElement = null,
  disableSelect = false,
  filters
}) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  // const [selectedRows, setSelectedRows] = useState(selectedIds)
  const [search, setSearch] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSearching, setIsSearching] = useState(false)

  const [sortBy, setSortBy] = useState({ filed: '', direction: '' })
  const totalPages = Math.ceil(total / perPage)

  const handlePageChange = pageNum => {
    setPage(pageNum)
    onFilter({ page: pageNum - 1 })
  }
  const handleRowsPerPage = count => {
    setPerPage(count)
    setPage(1)
    onFilter({ size: count, page: 0 })
  }
  const handleRowSelect = rowId => {
    // setSelectedRows(selectedRows.includes(rowId) ? selectedRows.filter(el => el !== rowId) : [...selectedRows, rowId])
    onSelect(selectedIds.includes(rowId) ? selectedIds.filter(el => el !== rowId) : [...selectedIds, rowId])
  }
  const handleSelectAll = () => {
    onSelect(selectedIds.length < rows.length ? rows.map(el => el.id) : [])
  }

  const onSort = sort => {
    setSortBy({ field: sort.field, direction: sort.direction })
    onFilter({ sort: `${sort.field},${sort.direction}` })
  }
  const onFilterCol = filter => {
    onFilter(filter)
  }

  useEffect(() => {
    setPage(1)
    onFilter({ size: perPage, page: 0 })
  }, [total])

  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true)
      onFilter({ searchText: search })
    } else {
      onFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  return (
    <div className='table-container'>
      <div className={`table-container-header row row-center ${selectedIds.length && 'table-container-header-active'}`}>
        {selectedIds.length ? (
          <>
            <div style={{ marginLeft: '24px' }}>
              <span
                style={{
                  fontFamily: 'Satoshi',
                  fontWeight: 500,
                  fontSize: '24px',
                  color: '#1B1B26'
                }}
              >
                {selectedIds.length}
              </span>{' '}
              {isSingle(selectedIds.length) ? 'item' : 'items'} selected
            </div>
            <div className='spacer'></div>
            {bulkActions}
          </>
        ) : (
          <>
            <SearchInput
              name='search'
              size='small'
              autoComplete='off'
              className='search'
              id='search'
              placeholder='Search'
              onChange={e => setSearch(e.target.value)}
              value={search}
              variant='outlined'
            />
            <div className='spacer'></div>
            {actions}
          </>
        )}
      </div>
      <div className={`row ${rows.length ? 'table' : ''}`}>
        {loading && (
          <div className='table-overlay'>
            <LoadingIndicator />
          </div>
        )}

        <div className='column'>
          <div className='table-header-cell'>
            {!disableSelect && (
              <CustomCheckbox
                checked={selectedIds.length == rows.length}
                indeterminate={selectedIds.length > 0 && selectedIds.length < rows.length}
                onChange={() => handleSelectAll()}
                sx={{ ml: '12px' }}
              />
            )}
          </div>
          {rows.map(row => (
            <div key={row.id} className={`table-cell ${selectedIds.includes(row.id) && 'table-cell-selected'}`}>
              {!disableSelect && (
                <CustomCheckbox checked={selectedIds.includes(row.id)} onChange={() => handleRowSelect(row.id)} />
              )}
            </div>
          ))}
        </div>
        {columns.map((col, index) => (
          <div
            key={col.headerName.replaceAll(' ', '').toLowerCase()}
            className='column'
            style={{ flex: index == 0 ? 2 : 1 }}
          >
            <div className='table-header-cell'>
              <TableHeaderButton filters={filters} total={total} sortBy={sortBy} onSort={onSort} onFilter={onFilterCol} col={col} />
            </div>
            {rows.map(row => (
              <div key={row.id} className={`table-cell ${selectedIds.includes(row.id) && 'table-cell-selected'}`}>
                {col?.renderCell
                  ? col.renderCell({
                      row: row,
                      action: onOpenElement,
                      search: search
                    })
                  : row[col.field]}
              </div>
            ))}
          </div>
        ))}
      </div>
      {!loading && rows.length == 0 && (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 325px)'
          }}
          className='column'
        >
          {emptyListElement}
        </div>
      )}

      <div className='row row-center' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          disabled={page == 1}
          variant='outlined'
          sx={{
            border: '1px solid #D0D0D8',
            color: '#1B1B26',
            textTransform: 'unset',
            mt: '18px',
            mb: '24px',
            mr: '24px',
            borderRadius: '12px',
            width: '148px'
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          variant='outlined'
          disabled={page == totalPages || !totalPages}
          sx={{
            border: '1px solid #D0D0D8',
            color: '#1B1B26',
            textTransform: 'unset',
            mt: '18px',
            mb: '24px',
            borderRadius: '12px',
            width: '148px'
          }}
        >
          Next
        </Button>
        <div className='spacer'></div>
        {totalPages ? <Pagination page={page} total={totalPages} onChange={handlePageChange} /> : null}

        <div className='spacer'></div>
        <RowsPerPage perPage={perPage} onChange={handleRowsPerPage} />
      </div>
    </div>
  )
}

export default DataGrid
