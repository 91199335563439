import React, { useState } from 'react'
import { Box, Drawer, Tooltip, Typography } from '@mui/material'
import { Letter } from 'react-letter'
import { CloseDrawer } from '../../../../common/icons/closeDrawer'
import CustomTemplatePreview from '../../../manual-simulation/components/templatePreview'
import TemplateView from '../../../../views/TemplateView'

export default function CustomTemplateCard({ template, number }) {
  const [isViewShowing, setIsViewShowing] = useState(false)
  return (
    <>
      <Box
        onClick={() => {
          setIsViewShowing(true)
        }}
        sx={{
          borderRadius: '12px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px',
          cursor: 'pointer'
        }}
      >
        <RoundBoxWithNumberInside number={number} />
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            width: '100%',
            height: '160px',
            borderRadius: '12px',
            overflow: 'hidden',
            cursor: 'pointer',
            border: '1px solid #E7E7EE80',
            backgroundColor: '#FCFCFC'
          }}
        >
          <Box
            sx={{
              width: '600px',
              backgroundColor: '#FCFCFC',
              transform: 'scale(0.3)',
              textAlign: 'center'
            }}
          >
            <Letter html={template?.body} />
          </Box>
        </Box>
        <Tooltip title={template?.subject}>
          <Typography noWrap fontWeight={600} fontSize={'14px'} fontFamily={'Plus Jakarta Sans'}>
            {template?.subject}
          </Typography>
        </Tooltip>
      </Box>
      {isViewShowing && (
        <Drawer
          anchor='right'
          open={isViewShowing}
          onClose={() => {
            setIsViewShowing(false)
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '24px',
              right: '1182px',
              cursor: 'pointer'
            }}
            onClick={() => {
              setIsViewShowing(false)
            }}
          >
            <CloseDrawer />
          </div>
          {template.type !== 'INTERNAL' && <CustomTemplatePreview id={template.id} />}
          {template.type === 'INTERNAL' && <TemplateView id={template.id} />}
        </Drawer>
      )}
    </>
  )
}

function RoundBoxWithNumberInside({ number }) {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 100,
        top: '25px',
        left: '-6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        background: 'linear-gradient(264.95deg, #00E5A0 -37.07%, #029E71 101.35%)',
        color: '#DFF2EF',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 700,
        fontSize: 12
      }}
    >
      {number}
    </Box>
  )
}
