import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export default function CampaignTimelines({ data }) {
  const navigate = useNavigate()
  function handleClick() {
    navigate(`steps/${data.id}`)
  }

  if (data?.sequenceNumber === 1) {
    return (
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer'
        }}
        display={'flex'}
        minWidth={'20%'}
        width={'20%'}
        flexDirection={'column'}
      >
        <Tooltip title={` ${dayjs(data.startDate).format('DD MMM')} ${timelineMapper[data.timeline]}`}>
          <Typography noWrap fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {dayjs(data.startDate).format('DD MMM')} {timelineMapper[data.timeline]}
          </Typography>
        </Tooltip>
        <Typography noWrap fontSize={'14px'} fontWeight={600} color={'#1B1B26'}>
          {typeMapper[data.type]}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        cursor: 'pointer'
      }}
      display={'flex'}
      minWidth={'20%'}
      width={'20%'}
      flexDirection={'column'}
      onClick={handleClick}
    >
      {data.triggerAction && (
        <Tooltip
          title={`${timelineMapper[data.timeline]} ${criteriaMapper[data?.triggerCriteria]} ${actionMapper[data.triggerAction]}`}
        >
          <Typography fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {timelineMapper[data.timeline]} {criteriaMapper[data?.triggerCriteria]} {actionMapper[data.triggerAction]}
          </Typography>
        </Tooltip>
      )}
      {!data.triggerAction && data.timeline && (
        <Tooltip title={`${timelineMapper[data.timeline]}`}>
          <Typography fontSize={'13px'} fontWeight={600} color={'#76767F'}>
            {timelineMapper[data.timeline]}
          </Typography>
        </Tooltip>
      )}
      <Typography fontSize={'14px'} fontWeight={600} color={'#1B1B26'}>
        {typeMapper[data.type]}
      </Typography>
    </Box>
  )
}

const typeMapper = {
  AUTOMATED_EMAIL: 'Automatic Email',
  MANUAL_EMAIL: 'Personalized Emails',
  SMS: 'Personalized SMS',
  WHATSAPP: 'Personalized WhatsApp message',
  SLACK: 'Automatic Slack message',
  PHONE: 'Automatic phone call'
}
const criteriaMapper = {
  USER_DID: 'user did',
  USER_NOT_DID: 'user did not'
}

const actionMapper = {
  CLICKED_LINK: 'clicked a link',
  OPENED_EMAIL: 'opened an email',
  OPENED_ATTACH: 'opened an attachment',
  SCANNED_QR_CODE: 'scanned a QR code',
  PROVIDED_CREDENTIALS: 'provided credentials',
  REPLIED_TO_EMAIL: 'replied to an email'
}
const timelineMapper = {
  IMMEDIATELY: 'Immediately',
  THROUGHOUT_DAY: 'Throughout the day',
  THROUGHOUT_WEEK: 'Throughout the week',
  THROUGHOUT_MONTH: 'Throughout the month',
  THROUGHOUT_3_MONTHS: 'Throughout 3 months',
  THROUGHOUT_6_MONTHS: 'Throughout 6 months',
  THROUGHOUT_YEAR: 'Throughout the year'
}
