import React, { useContext, useEffect, useState } from 'react'
import {
  DashBoardSectionBlock,
  DashBoardSectionBlockSubTitle,
  DashBoardSectionBlockTitle,
  DashBoardSectionlabel,
  Row
} from '..'
import Spacer from 'src/common/components/Spacer'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis } from 'recharts'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import statsService from 'src/services/stats.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { RiskiestList } from '../components/riskiestList'
import { Info } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { MODES, PeriodPicker } from '../components/periodPicker'
import { DashBoardDateContext } from 'src/App'

const data = [
  {
    name: '3 Apr',
    uv: 28
  },
  {
    name: '5 Apr',
    uv: 27
  },
  {
    name: '7 Apr',
    uv: 32
  },
  {
    name: '9 Apr',
    uv: 39
  },
  {
    name: '11 Apr',
    uv: 46
  },
  {
    name: '13 Apr',
    uv: 48
  },
  {
    name: 'Today',
    uv: 51
  }
]

const CustomizedLabel = props => {
  const { x, y, stroke, value } = props
  return value == '28' ? null : (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={16} textAnchor='middle'>
      {value}%
    </text>
  )
}
const CustomizedAxisTick = props => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} dx={-20} textAnchor='center' fill='#666' fontSize={'12px'}>
        {payload.value}
      </text>
    </g>
  )
}

export function PhishingClickRate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timePeriod, setTimePeriod] = useContext(DashBoardDateContext)

  // console.log(timePeriod);

  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [chart, setChart] = useState(null)
  const [risks, setRisks] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const getAllData = () => {
    const timePeriod3Month = {
      timePeriod: 'CUSTOM',
      startDate: dayjs(timePeriod.endDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: timePeriod.endDate
    }
    setLoading(true)
    Promise.all([
      statsService.getCurrentCompanySimulationStats(timePeriod),
      statsService.getSimulationDailyStats(timePeriod3Month),
      statsService.getTopRisksStats(timePeriod)
    ])
      .then(values => {
        const [simStatsResp, simulationDailyStatsResp, topRisksStatsResp] = values
        setStats(simStatsResp.data)
        setChart(simulationDailyStatsResp.data)
        setRisks(topRisksStatsResp.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (timePeriod) {
      getAllData()
    }
  }, [timePeriod])

  function getChartData(data) {
    const result = data
      .filter(el => el.phishedPercentage !== 0)
      .map(el => {
        return {
          name: dayjs(el.day).format('DD MMM'),
          fullName: el.day,
          phishing: el.phishedPercentage
        }
      })
    return result
  }

  const chartData = chart?.length ? getChartData(chart) : data

  const getComparedClickRate = () => {
    let prev = 0
    let current = 0

    chart?.forEach(element => {
      if (dayjs(element.day).month() == dayjs().month() && dayjs(element.day).year() == dayjs().year()) {
        current += element.phishedCount
      }
      if (
        dayjs(element.day).month() == dayjs().subtract(1, 'month').month() &&
        dayjs(element.day).year() == dayjs().year()
      ) {
        prev += element.phishedCount
      }
    })
    // console.log(prev, current);

    return {
      direction: prev > current ? 'down' : 'up',
      percentage: current == 0 ? 100 : prev == 0 ? 100 : ((prev - current) * 100) / prev
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const comparedClickRate = getComparedClickRate()

  const otherRisks = () => {
    let sum = 0
    const lastItems = risks.slice(3)

    lastItems.forEach(el => {
      sum += el.typePercentage
    })

    return {
      commonPercentage: sum,
      items: lastItems
    }
  }

  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-1030' style={{ minHeight: '100vh' }}>
        <div className='row row-center' onClick={() => navigate('/')} style={{ marginBottom: 14, cursor: 'pointer' }}>
          <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16 8.25H1.75' stroke='#6123FF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M8.5 15L1.75 8.25L8.5 1.5'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#6123FF', marginLeft: 8 }}>Back to Dashboard</p>
        </div>
        <div style={{ marginBottom: 24 }} className='row row-center'>
          <h1 style={{ margin: 0, fontSize: '36px' }}>
            Phishing click rate for{' '}
            {dayjs(timePeriod ? timePeriod.endDate : null).format(timePeriod?.mode == MODES.yearly ? 'YYYY' : 'MMMM')}
          </h1>
          <PeriodPicker />
          <div className='row'></div>
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Row style={{ marginBottom: 16 }}>
              <DashBoardSectionBlock style={{ width: 'calc(65% - 8px)' }}>
                <Row
                  style={{
                    marginBottom: 46,
                    alignItems: 'flex-start'
                  }}
                >
                  <div className='column'>
                    <DashBoardSectionBlockTitle>Phishing click rate</DashBoardSectionBlockTitle>
                    {/* <DashBoardSectionBlockSubTitle>{comparedClickRate.direction == 'up' ? 'Higher' : 'Lower'} by <span className={comparedClickRate.direction == 'up' ? 'red-text' : "green-text"} style={{ margin: '0px 6px' }}>{Math.round(comparedClickRate.percentage)}%
                      <svg style={{ margin: '0px 0px 3px 5px', transform: comparedClickRate.direction == 'up' ? 'rotate(0deg)' : 'rotate(180deg)' }} width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.529236 0.196875H8.52924L4.52924 5.79688L0.529236 0.196875Z" fill={comparedClickRate.direction == 'up' ? "#ED4563" : "#00BC62"} />
                      </svg>
                    </span>compared to {dayjs(timePeriod ? timePeriod.endDate : null).subtract(1, 'month').format('MMMM')}</DashBoardSectionBlockSubTitle> */}
                  </div>
                  <Spacer />
                  {!!stats?.phishedPercentage && (
                    <DashBoardSectionlabel
                      type={stats?.phishedPercentage < 33 ? 1 : stats?.phishedPercentage > 66 ? 3 : 2}
                    ></DashBoardSectionlabel>
                  )}
                </Row>
                <ResponsiveContainer
                  style={{
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                  width='100%'
                  height={250}
                >
                  <div
                    className='benchmark-sign-container'
                    style={{
                      width: '100%',
                      position: 'absolute',
                      bottom: '60%',
                      zIndex: 10
                    }}
                  >
                    <div
                      className='benchmark-sign'
                      style={{
                        opacity: '0',
                        transition: '300ms',
                        position: 'absolute',
                        background: 'rgb(229 249 251)',
                        borderRadius: 4,
                        border: '1px solid #00C8DC1A',
                        padding: '2px 6px 6px 6px',
                        left: 40,
                        zIndex: 10
                      }}
                    >
                      <span style={{ fontSize: '13px' }}>Benchmark — 60%</span>
                    </div>
                    <svg width='672' height='3' viewBox='0 0 672 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M0.5 1.16797C240 1.16797 179 4 672 1.16797' stroke='#00C8DC' strokeDasharray='2 2' />
                    </svg>
                  </div>
                  <AreaChart
                    data={chartData.slice(-10)}
                    margin={{
                      top: 30,
                      right: 30,
                      left: 30,
                      bottom: 0
                    }}
                  >
                    <defs>
                      <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#6123FF' stopOpacity={0.4} />
                        <stop offset='95%' stopColor='#6123FF' stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis tick={<CustomizedAxisTick />} dataKey='name' />
                    {/* <YAxis /> */}
                    <CartesianGrid horizontal={false} strokeDasharray='3 3' />
                    {/* <Tooltip /> */}
                    {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" label={<CustomizedLabel />} /> */}
                    <Area
                      dot={{ r: 2 }}
                      type='monotone'
                      dataKey='phishing'
                      stroke='#6123FF'
                      strokeWidth={2}
                      fillOpacity={1}
                      fill='url(#colorUv)'
                      label={<CustomizedLabel />}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </DashBoardSectionBlock>
              <Spacer />
              <DashBoardSectionBlock style={{ width: 'calc(35% - 8px)' }}>
                <Row>
                  <div className='column'>
                    <DashBoardSectionBlockTitle>Email sent</DashBoardSectionBlockTitle>
                    {/* <DashBoardSectionBlockSubTitle>Lower by <span className='green-text' style={{ margin: '0px 6px' }}>30%

                      <svg style={{ margin: '0px 0px 3px 5px' }} width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.529236 0.196875H8.52924L4.52924 5.79688L0.529236 0.196875Z" fill="#00BC62" />
                      </svg>
                    </span>
                      compared to March</DashBoardSectionBlockSubTitle> */}
                  </div>
                  <Spacer />
                </Row>

                <Row style={{ marginTop: 25, marginBottom: 32 }}>
                  <div className='column' style={{ flex: 1 }}>
                    <div
                      style={{
                        padding: 10,
                        height: 62,
                        marginBottom: 14,
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                      className='row row-center dash'
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: stats?.totalCount == 0 ? '0%' : '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <svg
                          width='290'
                          height='80'
                          viewBox='0 0 240 62'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g opacity='0.7'>
                            <path
                              opacity='0.1'
                              d='M52.0676 64.9958L154.533 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M69.4529 64.9958L171.919 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M86.8337 64.9958L189.299 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M104.215 64.9958L206.68 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M121.6 64.9958L224.065 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M138.981 64.9958L241.446 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M-86.7212 64.9958L15.7444 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M156.362 64.9958L258.827 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M-69.3362 64.9958L33.1294 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M173.747 64.9958L276.212 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M-51.9553 64.9958L50.5103 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M191.128 64.9958L293.593 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M-34.5703 64.9958L67.8953 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M208.513 64.9958L310.978 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M-17.1892 64.9958L85.2764 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M225.894 64.9958L328.359 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M0.195801 64.9958L102.661 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M17.5767 64.9958L120.042 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                            <path
                              opacity='0.1'
                              d='M34.9578 64.9958L137.423 -3.53906'
                              stroke='#292929'
                              strokeWidth='5.25451'
                            />
                          </g>
                        </svg>
                      </div>
                      <div className='column'>
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '14px',
                            fontWeight: 600
                          }}
                        >
                          Total attacks sent
                        </p>
                        <p className='secondary-text-14'>{stats?.totalCount == 0 ? '0' : '100'}%</p>
                      </div>
                      <Spacer />
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '24px',
                          fontFamily: 'Satoshi'
                        }}
                      >
                        {stats?.totalCount}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        height: 62,
                        position: 'relative',
                        marginBottom: 14,
                        borderTop: '2px solid #E0E8F7'
                      }}
                      className='row row-center'
                    >
                      <div
                        style={{
                          position: 'absolute',
                          width: stats?.openedPercentage + '%',
                          top: 0,
                          left: 0,
                          height: '100%',
                          background: '#E0E8F7'
                        }}
                      ></div>
                      <div className='column'>
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '14px',
                            fontWeight: 600
                          }}
                        >
                          Opened
                        </p>
                        <p className='secondary-text-14'>{stats?.openedPercentage}%</p>
                      </div>
                      <Spacer />
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '24px',
                          fontFamily: 'Satoshi'
                        }}
                      >
                        {stats?.openedCount}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        height: 62,
                        position: 'relative',
                        marginBottom: 14,
                        borderTop: '2px solid #F2D5CC'
                      }}
                      className='row row-center'
                    >
                      <div
                        style={{
                          position: 'absolute',
                          width: stats?.phishedPercentage + '%',
                          top: 0,
                          left: 0,
                          height: '100%',
                          background: '#F2D5CC'
                        }}
                      ></div>
                      <div className='column'>
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '14px',
                            fontWeight: 600
                          }}
                        >
                          Phished
                        </p>
                        <p className='secondary-text-14'>{stats?.phishedPercentage}%</p>
                      </div>
                      <Spacer />
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '24px',
                          fontFamily: 'Satoshi'
                        }}
                      >
                        {stats?.phishedCount}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        height: 62,
                        position: 'relative',
                        borderTop: '2px solid #01BD6325'
                      }}
                      className='row row-center'
                    >
                      <div
                        style={{
                          position: 'absolute',
                          width: stats?.reportedPercentage + '%',
                          top: 0,
                          left: 0,
                          height: '100%',
                          background: '#01BD6325'
                        }}
                      ></div>
                      <div className='column'>
                        <p
                          style={{
                            zIndex: 10,
                            fontSize: '14px',
                            fontWeight: 600
                          }}
                        >
                          Reported
                        </p>
                        <p className='secondary-text-14'>{stats?.reportedPercentage}%</p>
                      </div>
                      <Spacer />
                      <p
                        style={{
                          zIndex: 10,
                          fontSize: '24px',
                          fontFamily: 'Satoshi'
                        }}
                      >
                        {stats?.reportedCount}
                      </p>
                      {stats?.totalCount !== 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            left: '40%',
                            top: 0
                          }}
                          className='column column-center'
                        >
                          <svg width='2' height='79' viewBox='0 0 2 79' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <line
                              x1='1.50001'
                              y1='79.0032'
                              x2='1.00001'
                              y2='0.00316739'
                              stroke='#00C8DC'
                              strokeDasharray='2 2'
                            />
                          </svg>
                          <p
                            style={{
                              fontSize: '14px',
                              fontWeight: 600
                            }}
                          >
                            Benchmark — {Math.round(stats?.totalCount * 0.6)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Row>
              </DashBoardSectionBlock>
            </Row>

            <Row>
              <DashBoardSectionBlock style={{ width: 'calc(33% - 4px)' }}>
                <DashBoardSectionBlockTitle>TOP 3 Risks</DashBoardSectionBlockTitle>
                <p className='secondary-text-16'>Compared to {dayjs().subtract(1, 'month').format('MMMM')}</p>

                <div
                  style={{
                    height: 400,
                    borderRadius: 12,
                    overflow: 'hidden',
                    marginTop: 20
                  }}
                  className='column'
                >
                  {risks?.length == 0 && (
                    <div className='column column-center'>
                      <p style={{ marginTop: 160 }} className='secondary-text-16'>
                        No data yet
                      </p>
                    </div>
                  )}
                  {risks?.slice(0, 3).map((el, i) => (
                    <div
                      key={el.type}
                      className='column'
                      style={{
                        height: el.typePercentage + '%',
                        minHeight: el.typePercentage == 0 ? '15%' : el.typePercentage < 11 ? '18%' : '24%',
                        background: ['#F8F2E2', '#E2F7F8', '#FAEAF5', '#BFBFBF26'][i],
                        padding: '16px 36px',
                        borderBottom: '1px solid #fff'
                      }}
                    >
                      <Spacer />
                      <Row
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <p
                          style={{
                            fontWeight: '500'
                          }}
                        >
                          {el.type}
                        </p>
                        <Spacer />
                        <p style={{ fontSize: 20 }}>
                          {el.typePercentage}
                          <span style={{ fontSize: 16 }}>%</span>
                        </p>
                      </Row>
                    </div>
                  ))}
                  {risks?.length > 3 && (
                    <div
                      className='column'
                      style={{
                        height: '18%',
                        background: '#BFBFBF26',
                        padding: '16px 36px',
                        borderBottom: '1px solid #fff'
                      }}
                    >
                      <Spacer />
                      <Row
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <p
                          style={{
                            fontWeight: '500'
                          }}
                        >
                          Other
                        </p>
                        <Info
                          color='#1B1B26'
                          fontSize='16px'
                          sx={{
                            ml: '10px',
                            cursor: 'pointer'
                          }}
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        />

                        <Popover
                          id='mouse-over-popover'
                          sx={{
                            pointerEvents: 'none'
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <div
                            style={{
                              background: '#1B1B26',
                              color: '#fff',
                              padding: '12px 16px'
                            }}
                            className='column'
                          >
                            {otherRisks().items.map(el => (
                              <div key={el.type}>
                                {el.typePercentage}%{' '}
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    opacity: 0.7,
                                    fontSize: '14px',
                                    marginLeft: 6
                                  }}
                                >
                                  {' '}
                                  {el.type}
                                </span>
                              </div>
                            ))}
                          </div>
                        </Popover>
                        <Spacer />
                        <p style={{ fontSize: 20 }}>
                          {otherRisks().commonPercentage}
                          <span style={{ fontSize: 16 }}>%</span>
                        </p>
                      </Row>
                    </div>
                  )}
                </div>
              </DashBoardSectionBlock>

              <Spacer />

              <div className='column' style={{ width: 'calc(66% - 4px)' }}>
                <RiskiestList />
              </div>
            </Row>
          </>
        )}
      </div>
    </div>
  )
}
