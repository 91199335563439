import React, { useEffect, useState } from 'react'
import './styles.css'
// import CustomCheckbox from 'src/common/components/CustomCheckbox'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Divider,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton
} from '@mui/material'
import { styled } from '@mui/material/styles'

import StatusChip from 'src/common/components/StatusChip'
import EditIcon from 'src/common/icons/editIcon'
import DeleteIcon from 'src/common/icons/deleteIcon'
import ActivityIcon from 'src/common/icons/activityIcon'
import UserService from 'src/services/user.service'
import DeleteDialog from 'src/common/components/DeleteDialog'
import { SettingsContext, SnackBarContext } from 'src/App'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import statsService from 'src/services/stats.service'
import dayjs from 'dayjs'
import SendIcon from 'src/common/icons/send'
import userService from 'src/services/user.service'
import { sendInviteStatuses } from 'src/pages/team'
import DoneIcon from 'src/common/icons/done'
import { ToggleIcon } from 'src/common/icons/toggleIcon'
import { parseJwt } from 'src/heplers'
import UserAvatar from 'src/common/components/UserAvatar'
import utc from 'dayjs/plugin/utc'

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))
const InputSelect = styled(Select)(() => ({
  // border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E7E7EE'
  },

  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

  '& .MuiInputLabel-root': {
    top: '12px'
  }
}))

const TOTAL_STARS = 200

export const UserView = ({ userID, onActivate = () => {}, onDelete = () => {}, onUpdate = () => {} }) => {
  dayjs.extend(utc)
  const navigate = useNavigate()
  const settings = useContext(SettingsContext)

  const [openEditPopup, setOpenEditPopup] = useState(false)
  const [user, setUser] = useState(null)
  const [userSimStats, setUserSimStats] = useState(null)
  const [userTrainingStats, setUserTrainingStats] = useState(null)
  const [userActivity, setUserActivity] = useState(null)
  const [openDeteleDialog, setOpenDeleteDialog] = useState(false)
  const [openActivateDialog, setOpenActivateDialog] = useState(false)
  const [activitiesCountToShow, setActiviactivitiesCountToShow] = useState(50)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  // const [formCountry, setFormCountry] = useState()
  const [loading, setLoading] = useState(false)
  const [sendingInviteStatus, setSendingInviteStatus] = useState(sendInviteStatuses.notSent)

  const { setSuccessMessage } = useContext(SnackBarContext)

  const userLocal = JSON.parse(localStorage.getItem('user'))
  const localUserId = parseJwt(userLocal.accessToken).userId

  const sortActivity = activities => {
    return activities.sort((a, b) => {
      if (dayjs(a.actionAt).unix() < dayjs(b.actionAt).unix()) {
        return 1
      }
      if (dayjs(a.actionAt).unix() > dayjs(b.actionAt).unix()) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      UserService.getUser(userID),
      statsService.getUserSimulationStats(userID),
      statsService.getUserActivity(userID),
      statsService.getUserPhishingEmailStats(userID),
      statsService.getUserSecurityTrainingStats(userID)
    ]).then(
      values => {
        const [
          userDataResp,
          userSimStatsResp,
          userActivityResp,
          userPhishingEmailStatsResp,
          userSecurityTrainingStatsResp
        ] = values
        setUser(userDataResp.data)
        setForm(userDataResp.data)
        setUserSimStats(userSimStatsResp.data)
        setUserActivity(sortActivity(userActivityResp.data))
        setUserTrainingStats({
          phishingStats: userPhishingEmailStatsResp.data,
          trainingStats: userSecurityTrainingStatsResp.data
        })
        setLoading(false)
      },
      err => {
        if (err.response.status == 401) {
          navigate('/login')
        }
      }
    )
  }, [])

  const deleteUser = () => {
    UserService.delete(user.id).then(() => {
      setSuccessMessage('User deleted successfully')
      setOpenDeleteDialog(false)
      onDelete()
    })
  }

  const deactivateUser = () => {
    setLoading(true)
    UserService.deactivate(user.id)
      .then(() => {
        setUser({ ...user, status: 'INACTIVE' })
        setSuccessMessage('User deactivated successfully')
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const activateUser = () => {
    setLoading(true)
    UserService.activate(user.id)
      .then(() => {
        setUser({ ...user, status: 'ACTIVE' })
        setSuccessMessage('User activated successfully')
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const resetForm = () => {
    setForm(user)
  }

  const validateForm = () => {
    let errors = {}

    if (!form.email) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email is invalid'
    } else if (form.email.length > 40) {
      errors.email = 'The field can be no longer than 40 characters.'
    }

    if (!form.firstName) {
      errors.firstName = 'First Name is required'
    } else if (!/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż-]+$/.test(form.firstName)) {
      errors.firstName = 'First Name can contain only Latin characters and "-" '
    } else if (form.firstName.length > 40) {
      errors.firstName = 'The field can be no longer than 40 characters.'
    }

    if (!form.lastName) {
      errors.lastName = 'Last Name is required'
    } else if (!/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż-]+$/.test(form.lastName)) {
      errors.lastName = 'Last Name can contain only Latin characters and "-" '
    } else if (form.lastName.length > 40) {
      errors.lastName = 'The field can be no longer than 40 characters.'
    }

    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const hanldeSave = () => {
    const formInvalid = !form.email || !form.firstName || !form.lastName

    if (!formInvalid && validateForm()) {
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        language: form.language,
        department: form.department,
        country: form.country,
        email: form.email,
        phone: form.phone,
        status: form.status
      }

      UserService.update(user.id, payload).then(resp => {
        setOpenEditPopup(false)
        setUser({ ...user, ...resp.data })
        onUpdate()
      })
    }
    if (formInvalid) {
      validateForm()
    }
  }

  const handleSendInvitation = () => {
    setSendingInviteStatus(sendInviteStatuses.loading)
    userService.sendInvite(userID).then(() => {
      setSendingInviteStatus(sendInviteStatuses.sent)
    })
  }

  return (
    <div className='row user-view' style={{ width: '1030px' }}>
      {loading && <div className='user-view-loading'></div>}
      <div className='user-details'>
        <div style={{ marginBottom: '20px' }} className='row row-center'>
          {user?.image ? (
            <div className='user-image'>
              <img src='https://pl.unbilgi.com/wp-content/uploads/2020/08/566974035682.jpg' alt='user img' />
            </div>
          ) : loading ? (
            <Skeleton sx={{ mr: '32px' }} variant='circular' width={100} height={100} />
          ) : (
            <UserAvatar
              firstName={user?.firstName}
              lastName={user?.lastName}
              style={{
                width: 100,
                height: 100,
                borderRadius: 50,
                fontSize: 28
              }}
            />
          )}
          <p className='user-view-user-name text-shrink-2'>
            {loading ? (
              <Skeleton animation='wave' width={150} />
            ) : (
              (user?.firstName || 'unknown') + ' ' + (user?.lastName || 'unknown')
            )}
          </p>
        </div>
        <div className='column'>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Status</p>
            <StatusChip style={{ paddingLeft: '0px' }} value={user?.status} />
          </div>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Email</p>
            <p className='user-details-text'>{user?.email}</p>
          </div>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Phone</p>
            <p className='user-details-text'>{user?.phone || '-'}</p>
          </div>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Country</p>
            <p className='user-details-text'>{user?.country || 'n/a'}</p>
          </div>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Department</p>
            <p className='user-details-text'>{user?.department || 'n/a'}</p>
          </div>
          <div className='row row-center user-details-group'>
            <p className='user-details-label'>Language</p>
            <p className='user-details-text'>{user?.language || 'n/a'}</p>
          </div>
        </div>

        {user?.status !== 'INACTIVE' && localUserId !== userID && (
          <div style={{ marginTop: '55px' }} className='row'>
            <Button
              variant='outlined'
              disabled={
                loading ||
                sendingInviteStatus == sendInviteStatuses.loading ||
                sendingInviteStatus == sendInviteStatuses.sent
              }
              onClick={handleSendInvitation}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1'
              }}
              startIcon={
                sendingInviteStatus == sendInviteStatuses.sent ? (
                  <DoneIcon />
                ) : (
                  <SendIcon
                    disabled={
                      loading ||
                      sendingInviteStatus == sendInviteStatuses.loading ||
                      sendingInviteStatus == sendInviteStatuses.sent
                    }
                  />
                )
              }
            >
              {sendingInviteStatus == sendInviteStatuses.sent ? 'Welcome email sent' : 'Send welcome email'}
            </Button>
          </div>
        )}

        {user?.status !== 'INACTIVE' && (
          <div style={{ marginTop: '15px' }} className='row'>
            <Button
              variant='outlined'
              disabled={loading}
              onClick={() => setOpenEditPopup(true)}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1'
              }}
              startIcon={<EditIcon disabled={loading} />}
            >
              Edit information
            </Button>
          </div>
        )}
        {localUserId !== userID && (
          <div style={{ marginTop: '15px' }} className='row'>
            <Button
              disabled={loading}
              variant='outlined'
              onClick={user?.status == 'INACTIVE' ? activateUser : deactivateUser}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: 1,
                mr: '10px'
              }}
              startIcon={<ToggleIcon />}
            >
              {user?.status == 'INACTIVE' ? 'Activate' : 'Deactivate'} user
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              onClick={() => setOpenDeleteDialog(true)}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#B40020',
                textTransform: 'none'
              }}
              startIcon={<DeleteIcon />}
            >
              Delete user
            </Button>
          </div>
        )}
      </div>
      <div className='user-info'>
        <Paper sx={{ padding: '16px 32px', mb: '10px' }}>
          <p className='user-info-paper-title'>Simulations sent</p>
          <div className='row row-center'>
            <p className='user-info-paper-big-num'>{userSimStats?.totalCount}</p>
            <div className='spacer'></div>
            <div className='user-info-progress'>
              {/* to refactor */}
              <div
                className='row row-center'
                style={{
                  height: '8px',
                  width: '190px',
                  backgroundColor: '#E7E7EE',
                  position: 'relative',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}
              >
                {userSimStats?.reportedPercentage ? (
                  <div
                    style={{
                      width: userSimStats.reportedPercentage + '%',
                      // position: 'absolute',
                      left: '0px',
                      top: '0px',
                      backgroundColor: '#00BC62',
                      borderRadius: '8px',
                      minWidth: '8px',
                      marginRight: '-4px',
                      height: '100%'
                    }}
                  ></div>
                ) : null}
                {userSimStats?.phishedPercentage ? (
                  <div
                    style={{
                      width: userSimStats.phishedPercentage + '%',
                      backgroundColor: '#F0284C',
                      borderRadius: '8px',
                      height: '100%',
                      border: '4px solid #fff',
                      boxSizing: 'content-box'
                    }}
                  ></div>
                ) : null}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }} className='row row-center'>
            <svg
              style={{ flexShrink: 0 }}
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle opacity='0.1' cx='12' cy='12.5' r='12' fill='#00BC62' />
              <path d='M7.5 17L15.75 8.75L16.125 8.375' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M9.1875 8H16.5V15.3125' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <p className='user-info-paper-mid-num'>
              {userSimStats?.totalCount && Math.round((userSimStats.reportedCount / userSimStats.totalCount) * 100)}%
            </p>
            <p>Reported</p>
            <svg
              style={{ marginLeft: '20px', flexShrink: 0 }}
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle opacity='0.1' cx='12' cy='12.5' r='12' transform='rotate(180 12 12.5)' fill='#B40020' />
              <path d='M16.5 8L8.25 16.25L7.875 16.625' stroke='#B40020' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M14.8125 17H7.5V9.6875' stroke='#B40020' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <p className='user-info-paper-mid-num'>
              {userSimStats?.totalCount && Math.round((userSimStats.phishedCount / userSimStats.totalCount) * 100)}%
            </p>
            <p>Phished</p>
            <svg
              style={{ marginLeft: '20px', flexShrink: 0 }}
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle opacity='0.1' cx='12' cy='12.5' r='12' transform='rotate(180 12 12.5)' fill='#76767F' />
              <path d='M16.5 8L8.25 16.25L7.875 16.625' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
              <path d='M14.8125 17H7.5V9.6875' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
            <p className='user-info-paper-mid-num'>
              {userSimStats?.totalCount &&
                (userSimStats?.openedCount
                  ? Math.round((userSimStats.openedCount / userSimStats.totalCount) * 100)
                  : 0)}
              %
            </p>
            <p>Opened</p>
          </div>
        </Paper>
        <Paper sx={{ padding: '16px 32px', mb: '10px' }}>
          <p className='user-info-paper-title'>Stars</p>
          <div className='row row-center'>
            <p className='user-info-paper-big-num'>
              {user?.stars}/{TOTAL_STARS}
            </p>
            <div className='spacer'></div>
            <div className='user-info-progress-stars'>
              <div className='user-info-progress-stars-bar'>
                <div
                  className='user-info-progress-stars-filled'
                  style={{
                    width: `${user?.stars > TOTAL_STARS ? 100 : Math.round((user?.stars / TOTAL_STARS) * 100)}%`
                  }}
                ></div>
                <div
                  style={{
                    left: `calc(${user?.stars > TOTAL_STARS ? 100 : Math.round((user?.stars / TOTAL_STARS) * 100)}% - 18px )`
                  }}
                  className='user-info-progress-stars-indicator'
                >
                  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <g clipPath='url(#clip0_360_38335)'>
                      <path
                        d='M15.3705 4.38469C16.1052 2.12336 19.3044 2.12335 20.0392 4.38468L22.0286 10.5074C22.3571 11.5187 23.2996 12.2034 24.3629 12.2034H30.8007C33.1784 12.2034 34.167 15.246 32.2434 16.6436L27.0351 20.4276C26.1749 21.0526 25.8149 22.1605 26.1435 23.1718L28.1329 29.2945C28.8676 31.5558 26.2794 33.4363 24.3558 32.0387L19.1475 28.2546C18.2873 27.6296 17.1224 27.6296 16.2621 28.2546L11.0539 32.0387C9.13025 33.4363 6.54205 31.5558 7.2768 29.2945L9.26619 23.1718C9.59478 22.1605 9.23481 21.0526 8.37455 20.4276L3.16627 16.6436C1.24267 15.246 2.23126 12.2034 4.60897 12.2034H11.0468C12.1101 12.2034 13.0525 11.5187 13.3811 10.5074L15.3705 4.38469Z'
                        fill='url(#paint0_radial_360_38335)'
                      />
                      <path
                        d='M20.7038 4.16873C19.7599 1.26353 15.6498 1.26354 14.7058 4.16873L12.7165 10.2914C12.4814 11.0148 11.8073 11.5045 11.0468 11.5045H4.60897C1.55426 11.5045 0.284196 15.4135 2.7555 17.209L7.96377 20.993C8.57909 21.44 8.83657 22.2325 8.60154 22.9558L6.61215 29.0785C5.6682 31.9837 8.99333 34.3996 11.4646 32.6041L16.6729 28.82C17.2882 28.373 18.1214 28.373 18.7368 28.82L23.945 32.6041C26.4163 34.3996 29.7415 31.9837 28.7975 29.0785L26.8081 22.9558C26.5731 22.2325 26.8306 21.44 27.4459 20.993L32.6542 17.209C35.1255 15.4134 33.8554 11.5045 30.8007 11.5045H24.3629C23.6023 11.5045 22.9282 11.0148 22.6932 10.2914L20.7038 4.16873Z'
                        stroke='#FAD260'
                        strokeWidth='1.39771'
                      />
                      <mask id='mask0_360_38335' maskUnits='userSpaceOnUse' x='2' y='2' width='32' height='31'>
                        <path
                          d='M15.3705 4.38469C16.1052 2.12336 19.3044 2.12335 20.0392 4.38468L22.0286 10.5074C22.3571 11.5187 23.2996 12.2034 24.3629 12.2034H30.8007C33.1784 12.2034 34.167 15.246 32.2434 16.6436L27.0351 20.4276C26.1749 21.0526 25.8149 22.1605 26.1435 23.1718L28.1329 29.2945C28.8676 31.5558 26.2794 33.4363 24.3558 32.0387L19.1475 28.2546C18.2873 27.6296 17.1224 27.6296 16.2621 28.2546L11.0539 32.0387C9.13025 33.4363 6.54205 31.5558 7.2768 29.2945L9.26619 23.1718C9.59478 22.1605 9.23481 21.0526 8.37455 20.4276L3.16627 16.6436C1.24267 15.246 2.23126 12.2034 4.60897 12.2034H11.0468C12.1101 12.2034 13.0525 11.5187 13.3811 10.5074L15.3705 4.38469Z'
                          fill='url(#paint1_radial_360_38335)'
                        />
                      </mask>
                      <g mask='url(#mask0_360_38335)'>
                        <circle cx='12.1425' cy='2.95844' r='18.5974' fill='url(#paint2_radial_360_38335)' />
                      </g>
                    </g>
                    <defs>
                      <radialGradient
                        id='paint0_radial_360_38335'
                        cx='0'
                        cy='0'
                        r='1'
                        gradientUnits='userSpaceOnUse'
                        gradientTransform='translate(3.63881 5.50777) rotate(37.4894) scale(54.1337)'
                      >
                        <stop stopColor='#FAD261' />
                        <stop offset='1' stopColor='#FDEEC4' />
                      </radialGradient>
                      <radialGradient
                        id='paint1_radial_360_38335'
                        cx='0'
                        cy='0'
                        r='1'
                        gradientUnits='userSpaceOnUse'
                        gradientTransform='translate(3.63881 5.50777) rotate(37.4894) scale(54.1337)'
                      >
                        <stop stopColor='#FFC700' />
                        <stop offset='1' stopColor='#FF9900' />
                      </radialGradient>
                      <radialGradient
                        id='paint2_radial_360_38335'
                        cx='0'
                        cy='0'
                        r='1'
                        gradientUnits='userSpaceOnUse'
                        gradientTransform='translate(8.0832 -1.28969) rotate(55.9379) scale(30.9602)'
                      >
                        <stop offset='0.140625' stopColor='#FAC733' />
                        <stop offset='1' stopColor='#FBD360' />
                      </radialGradient>
                      <clipPath id='clip0_360_38335'>
                        <rect width='36' height='36' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  style={{
                    left: `calc(${user?.stars > TOTAL_STARS ? 100 : Math.round((user?.stars / TOTAL_STARS) * 100)}% - 8px )`
                  }}
                  className='user-info-progress-stars-text'
                >
                  <p>
                    {Math.round((user?.stars / TOTAL_STARS) * 100)}
                    <span style={{ color: '#76767F' }}>%</span>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='column' style={{ marginRight: '32px', marginTop: '16px' }}>
              <div className='row row-center'>
                <p className='user-info-paper-mid-num' style={{ marginLeft: '0' }}>
                  {userTrainingStats?.trainingStats.completedCount}
                </p>
                <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M13.875 8.375L5.625 16.625L1.5 12.5002'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M22.4998 8.375L14.2498 16.625L12.0586 14.4339'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <p style={{ whiteSpace: 'nowrap' }}>Completed training</p>
            </div>
            <div className='column' style={{ marginRight: '32px', marginTop: '16px' }}>
              <div className='row row-center'>
                <p className='user-info-paper-mid-num' style={{ marginLeft: '0' }}>
                  {userTrainingStats?.phishingStats.fishingCount}
                </p>
                <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.1'
                    d='M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z'
                    fill='#5F5F68'
                  />
                  <path
                    d='M12 20.75C16.5563 20.75 20.25 17.0563 20.25 12.5C20.25 7.94365 16.5563 4.25 12 4.25C7.44365 4.25 3.75 7.94365 3.75 12.5C3.75 17.0563 7.44365 20.75 12 20.75Z'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path d='M12 2.375V6.125' stroke='#5F5F68' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M1.875 12.5H5.625' stroke='#5F5F68' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M12 22.625V18.875' stroke='#5F5F68' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M22.125 12.5H18.375' stroke='#5F5F68' strokeLinecap='round' strokeLinejoin='round' />
                  <path
                    d='M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <p style={{ whiteSpace: 'nowrap' }}>Phish reports</p>
            </div>
            <div className='column' style={{ marginRight: '32px', marginTop: '16px' }}>
              <div className='row row-center'>
                <p className='user-info-paper-mid-num' style={{ marginLeft: '0' }}>
                  {userTrainingStats?.phishingStats.spamCount}
                </p>
                <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.1'
                    d='M18.5625 8.75V17C18.5625 17.1823 18.4901 17.3572 18.3611 17.4861C18.2322 17.6151 18.0573 17.6875 17.875 17.6875H4.125C3.94266 17.6875 3.7678 17.6151 3.63886 17.4861C3.50993 17.3572 3.4375 17.1823 3.4375 17V8.75H18.5625Z'
                    fill='#5F5F68'
                  />
                  <path
                    d='M19.25 5.3125H2.75C2.3703 5.3125 2.0625 5.6203 2.0625 6V8.0625C2.0625 8.4422 2.3703 8.75 2.75 8.75H19.25C19.6297 8.75 19.9375 8.4422 19.9375 8.0625V6C19.9375 5.6203 19.6297 5.3125 19.25 5.3125Z'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M18.5625 8.75V17C18.5625 17.1823 18.4901 17.3572 18.3611 17.4861C18.2322 17.6151 18.0573 17.6875 17.875 17.6875H4.125C3.94266 17.6875 3.7678 17.6151 3.63886 17.4861C3.50993 17.3572 3.4375 17.1823 3.4375 17V8.75'
                    stroke='#5F5F68'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path d='M8.9375 12.1875H13.0625' stroke='#5F5F68' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </div>
              <p style={{ whiteSpace: 'nowrap' }}>Spam reports</p>
            </div>
          </div>
        </Paper>
        <div className='activity-history'>
          <div className='row row-center' style={{ marginBottom: '20px' }}>
            <p className='activity-history-title'>Activity history</p>
          </div>
          {(!userActivity || userActivity.length == 0) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#76767F',
                height: '300px'
              }}
            >
              <p>No Activities yet</p>
            </div>
          )}
          {userActivity &&
            userActivity.slice(0, activitiesCountToShow).map(el => (
              <div key={el.id} className='row row-center' style={{ marginBottom: '20px' }}>
                <ActivityIcon type={el.name} />
                <div className='column'>
                  <p className='activity-history-activity-name'>{el.description}</p>
                  {el.location && el.browser && (
                    <div
                      style={{
                        margin: '6px 0px 0px 16px'
                      }}
                      className='row row-center'
                    >
                      <p
                        style={{
                          color: '#76767F',
                          fontSize: '13px',
                          margin: 0
                        }}
                        className='activity-history-activity-name'
                      >
                        {el.location}
                      </p>
                      <svg
                        style={{
                          margin: '0px 8px'
                        }}
                        width='4'
                        height='4'
                        viewBox='0 0 4 4'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle opacity='0.5' cx='2' cy='2' r='2' fill='#76767F' />
                      </svg>
                      <p
                        style={{
                          color: '#76767F',
                          fontSize: '13px',
                          margin: 0
                        }}
                        className='activity-history-activity-name'
                      >
                        {el.browser}
                      </p>
                    </div>
                  )}
                </div>
                <div className='spacer'></div>
                <p className='activity-history-datetime'>{dayjs.utc(el.actionAt).local().format('D MMM HH:mm')}</p>
              </div>
            ))}
          {activitiesCountToShow < userActivity?.length && (
            <div style={{ justifyContent: 'center' }} className='row row-center'>
              <Button
                onClick={() => setActiviactivitiesCountToShow(activitiesCountToShow + 50)}
                sx={{ color: '#6123FF', marginBottom: '30px' }}
                variant={'text'}
              >
                Show more
              </Button>
            </div>
          )}
        </div>
      </div>

      <DeleteDialog
        description='This user will be deleted permanently and beyond recovery'
        onClose={() => setOpenDeleteDialog(false)}
        onAction={deleteUser}
        str={user?.firstName + ' ' + user?.lastName}
        open={openDeteleDialog}
      />

      <Dialog
        sx={{
          '& .MuiPaper-root': { borderRadius: '22px' },
          zIndex: 1300
        }}
        open={openEditPopup}
        onClose={() => {
          setOpenEditPopup(false)
          resetForm()
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: '900',
            fontSize: '35px',
            lineHeight: '110%',
            color: '#1B1B26'
          }}
        >
          Edit user info
        </DialogTitle>
        <DialogContent sx={{ width: '600px' }}>
          <p style={{ marginBottom: '25px' }}>Updating user details will receive a privacy audit</p>

          <InputText
            required={true}
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='First name'
            variant='standard'
            onChange={e => {
              setForm({ ...form, firstName: e.target.value })
              setErrors({ ...errors, firstName: null })
            }}
            value={form?.firstName}
            error={errors.firstName}
          />
          {errors.firstName && (
            <p
              style={{
                color: '#B40020',
                marginLeft: 16,
                marginTop: -10,
                marginBottom: 20
              }}
            >
              {errors.firstName}
            </p>
          )}
          <InputText
            required={true}
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Last name'
            variant='standard'
            onChange={e => {
              setForm({ ...form, lastName: e.target.value })
              setErrors({ ...errors, lastName: null })
            }}
            value={form?.lastName}
            error={errors.lastName}
          />
          {errors.lastName && (
            <p
              style={{
                color: '#B40020',
                marginLeft: 16,
                marginTop: -10,
                marginBottom: 20
              }}
            >
              {errors.lastName}
            </p>
          )}
          <InputText
            disabled
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Email'
            variant='standard'
            onChange={e => {
              setForm({ ...form, email: e.target.value })
            }}
            value={form?.email}
          />
          <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Phone'
            variant='standard'
            onChange={e => {
              setForm({
                ...form,
                phone: e.target.value.replace(/[^0-9+]/g, '')
              })
            }}
            value={form?.phone}
          />
          <FormControl fullWidth>
            <InputLabel id='Country'>Country</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}
              labelId='Country'
              value={form?.country}
              label='Country'
              onChange={e => setForm({ ...form, country: e.target.value })}
            >
              {settings?.countries?.map(el => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </InputSelect>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id='Department'>Department</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}
              labelId='Department'
              value={form?.department}
              label='Department'
              onChange={e => setForm({ ...form, department: e.target.value })}
            >
              {settings?.departments?.map(el => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </InputSelect>
          </FormControl>
          {/* <InputText
            fullWidth
            sx={{ borderRadius: '20px', mb: '18px' }}
            label='Site'
            variant='standard'
            value={user?.site || ''}
          /> */}
          <FormControl fullWidth>
            <InputLabel id='Language'>Language</InputLabel>
            <InputSelect
              sx={{ borderRadius: '20px', mb: '18px' }}
              labelId='Language'
              value={form?.language}
              label='Language'
              onChange={e => setForm({ ...form, language: e.target.value })}
            >
              {settings?.languages?.map(el => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </InputSelect>
          </FormControl>
        </DialogContent>
        <Divider />
        <div className='row'>
          <Button
            onClick={() => {
              setOpenEditPopup(false)
              resetForm()
            }}
            sx={{
              height: '60px',
              borderRadius: '14px',
              color: '#6123FF',
              borderColor: '#6123FF',
              flex: '1',
              margin: '24px 16px 24px 32px'
            }}
            variant={'outlined'}
          >
            Cancel
          </Button>
          <Button
            onClick={hanldeSave}
            sx={{
              height: '60px',
              borderRadius: '14px',
              backgroundColor: '#6123FF',
              flex: '1',
              margin: '24px 32px 24px 0px'
            }}
            variant={'contained'}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
