import React from 'react'
import TemplatesFilter from './TemplatesFilter'
import { Box } from '@mui/material'

import TemplateCardList from './TemplateCardList'
import { Field } from 'react-final-form'
import CustomTemplateCardList from './CustomTemplateCardList'
import NewTemplateCard from './NewTemplateCard'

export default function ChooseTemplate() {
  return (
    <Box display={'flex'}>
      <TemplatesFilter />
      <Box display={'flex'} flexDirection={'column'} p={'16px'} rowGap={'16px'} width={'100%'}>
        <Box
          width={'100%'}
          display={'flex'}
          gap={'16px'}
          height={'calc(90vh - 120px)'}
          sx={{
            '::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#DBDBE6',
              borderRadius: '2px',
              '&:hover': {
                backgroundColor: '#DBDBE6'
              }
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'transparent'
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#DBDBE6 transparent'
          }}
          overflow={'auto'}
          id='templateList'
        >
          <Box display={'flex'} gap={'16px'} flexDirection={'column'} width={'100%'}>
            <NewTemplateCard />
            <Field name={'customTemplateIds'} component={CustomTemplateCardList} />
            <Field name={'internalTemplateIds'} component={TemplateCardList} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
