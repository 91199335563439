import axios from 'axios'
import authHeader from './auth-header'
import api from './api.service'

const API_URL = window._env_.REACT_APP_API_HOST + '/v1/'

class TrainingsService {
  getTrainings(lang = 'English') {
    return api.get('/v1/trainings?language=' + lang, {
      headers: authHeader()
    })
  }

  enable(id, active) {
    return axios.post(API_URL + 'trainings/enable', { active: active, ids: [id] }, { headers: authHeader() })
  }

  getModules(id, lang = 'English') {
    return axios.get(API_URL + 'trainings/' + id + '/modules?language=' + lang, { headers: authHeader() })
  }

  getAllModules(lang = 'English') {
    return axios.get(API_URL + 'trainings/modules?language=' + lang, {
      headers: authHeader()
    })
  }

  getModulesByIds(ids) {
    return axios.post(API_URL + 'trainings/modules', { ids }, { headers: authHeader() })
  }

  getModulePreview(trainingId, moduleId, lang = 'English') {
    return axios.get(API_URL + 'trainings/' + trainingId + '/modules/' + moduleId + '?language=' + lang, {
      headers: authHeader()
    })
  }

  enableModule(trainingId, moduleId, active) {
    return axios.post(
      API_URL + 'trainings/' + trainingId + '/modules/enable',
      { active: active, ids: [moduleId] },
      { headers: authHeader() }
    )
  }

  // Packages:
  getPackages(searchText = '') {
    return api.get('/v1/training-packages' + '?searchText=' + searchText, {
      headers: authHeader()
    })
  }
  getPackage(id) {
    return axios.get(API_URL + 'training-packages/' + id, {
      headers: authHeader()
    })
  }
  addPackage(packageBody) {
    return axios.post(API_URL + 'training-packages/', packageBody, {
      headers: authHeader()
    })
  }
  updatePackage(id, packageBody) {
    return axios.put(API_URL + 'training-packages/' + id, packageBody, {
      headers: authHeader()
    })
  }
  publishPackage(id) {
    return axios.post(API_URL + 'training-packages/' + id + '/publish', {}, { headers: authHeader() })
  }
  archivePackage(id) {
    return axios.post(API_URL + 'training-packages/' + id + '/archive', {}, { headers: authHeader() })
  }
}

export default new TrainingsService()
