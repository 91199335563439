import React, { useContext, useState } from 'react'
import { PageNumberContext } from './PageNumberContext'
import ChooseTypeCard from './ChooseTypeCard'
import { Field, useFormState } from 'react-final-form'
import { Box, Divider, Radio, Typography } from '@mui/material'
import GenericSelect from '../../../common/components/GenericSelect'
import { messagesType, timelineOptions, triggerActionOptions, triggerCriteriaOptions } from './utils'
import FormStepper from './FormStepper'

export default function SecondPageForOtherStepsForm() {
  const { values } = useFormState()
  const { setPage } = useContext(PageNumberContext)
  const isSmsTemplate = values?.metadata?.phoneNumber
  const emailTemplatesCount = isNaN(values?.internalTemplateIds?.length + values?.customTemplateIds?.length)
    ? 0
    : values?.internalTemplateIds?.length + values?.customTemplateIds?.length
  const [radioValue, setRadioValue] = useState(values.triggerCriteria ? 'TRIGGER' : 'SCHEDULE')

  const cardMapper = {
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'Email',
      description: 'Send automated emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Personalized Emails',
      description: 'Craft and dispatch custom emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      onChange: () => setPage(1)
    },
    WHATSAPP: {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      isAI: false,
      onChange: () => setPage(1)
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    SMS_TEMPLATE: {
      type: 'SMS_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      onChange: () => {
        setPage('SMS_TEMPLATE')
      },
      number: isSmsTemplate && 1,
      isDisabled: false
    },
    EMAIL_TEMPLATE: {
      type: 'EMAIL_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      onChange: () => {
        setPage('EMAIL_TEMPLATE')
      },
      number: emailTemplatesCount,
      isDisabled: false
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[values?.type]} />
      {messagesType.includes(values?.type) && <ChooseTypeCard {...cardMapper['SMS_TEMPLATE']} />}
      {values?.type === 'MANUAL_EMAIL' && <ChooseTypeCard {...cardMapper['EMAIL_TEMPLATE']} />}
      <Box display={'flex'} columnGap={1} alignItems={'center'}>
        <Radio
          disableRipple={true}
          value={'SCHEDULE'}
          checked={radioValue === 'SCHEDULE'}
          onChange={e => setRadioValue(e.target.value)}
        />
        <Typography color={'#1B1B26'} fontWeight={500} fontSize={'16px'} fontFamily={'Plus Jakarta Sans'}>
          Schedule
        </Typography>
        <Box pl={'36px'}>
          <Field
            name={radioValue === 'SCHEDULE' ? 'timeline' : 'a'}
            render={({ input }) => (
              <GenericSelect
                label={'Timeline'}
                disabled={radioValue !== 'SCHEDULE'}
                options={timelineOptions}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </Box>
      </Box>
      <Divider variant={'middle'} orientation={'horizontal'} />
      <Box display={'flex'} columnGap={2}>
        <Radio
          disableRipple={true}
          value={'TRIGGER'}
          checked={radioValue === 'TRIGGER'}
          onChange={e => setRadioValue(e.target.value)}
        />
        <Box display={'flex'} columnGap={'12px'} alignItems={'center'}>
          <FormStepper />
          <Box display={'flex'} flexDirection={'column'} rowGap={'24px'}>
            <Box display={'flex'} columnGap={'12px'} alignItems={'center'}>
              <Typography
                pr={'12px'}
                width={'55px'}
                color={'#1B1B26'}
                fontWeight={500}
                fontSize={'16px'}
                fontFamily={'Plus Jakarta Sans'}
              >
                Trigger
              </Typography>
              <Field
                name={radioValue === 'TRIGGER' ? 'triggerCriteria' : 'a'}
                render={({ input }) => (
                  <GenericSelect
                    label={'Trigger Criteria'}
                    disabled={radioValue !== 'TRIGGER'}
                    options={triggerCriteriaOptions}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              />
              <Field
                name={radioValue === 'TRIGGER' ? 'triggerAction' : ' b'}
                render={({ input }) => (
                  <GenericSelect
                    label={'Trigger Action'}
                    disabled={radioValue !== 'TRIGGER'}
                    options={triggerActionOptions}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              />
            </Box>
            <Box display={'flex'} columnGap={'12px'} alignItems={'center'}>
              <Typography
                pr={'12px'}
                width={'55px'}
                color={'#1B1B26'}
                fontWeight={500}
                fontSize={'16px'}
                fontFamily={'Plus Jakarta Sans'}
              >
                Start
              </Typography>
              <Field
                name={radioValue === 'TRIGGER' ? 'timeline' : 'c'}
                render={({ input }) => (
                  <GenericSelect
                    label={'Timeline'}
                    disabled={radioValue !== 'TRIGGER'}
                    options={timelineOptions}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
