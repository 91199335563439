import React, { useEffect, useState } from 'react'
import './styles.css'
import CyberSwitch from 'src/common/components/Switch'
import Spacer from 'src/common/components/Spacer'
import { Button, Dialog, DialogContent, DialogTitle, Drawer } from '@mui/material'
import EditIcon from 'src/common/icons/editIcon'
import RadioSelect from 'src/common/components/RadioSelect'
import trainingsService from 'src/services/trainings.service'
import { useNavigate } from 'react-router-dom'
import TrainingCardsV3 from 'src/common/components/training-cards-v3'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { CloseDrawer } from 'src/common/icons/closeDrawer'

const PlanetIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.15'
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      fill='#6123FF'
    />
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      stroke='#6123FF'
      strokeMiterlimit='10'
    />
    <path d='M3.51172 9H20.4866' stroke='#6123FF' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.51562 15H20.4904' stroke='#6123FF' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12 20.759C14.0711 20.759 15.75 16.8377 15.75 12.0006C15.75 7.16346 14.0711 3.24219 12 3.24219C9.92893 3.24219 8.25 7.16346 8.25 12.0006C8.25 16.8377 9.92893 20.759 12 20.759Z'
      stroke='#6123FF'
      strokeMiterlimit='10'
    />
  </svg>
)

const ModuleItem = ({ item, index, onPreview }) => {
  return (
    <div className={`row row-center module-element ${item.active && 'module-element-active'}`}>
      <p style={{ marginLeft: '10px' }}>
        {index + 1}.{item.module}
      </p>
      <Spacer />
      <Button onClick={onPreview} sx={{ color: '#6123FF', textTransform: 'none' }} startIcon={<PlanetIcon />}>
        Preview
      </Button>
    </div>
  )
}

const MicroTrainingPreview = ({ item, modules, modulesLoading, onClose, onModulePreview }) => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '24px',
          right: '982px',
          cursor: 'pointer'
        }}
        onClick={onClose}
      >
        <CloseDrawer />
      </div>
      <div style={{ width: '947px', padding: '32px' }} className='column'>
        <div className='row row-center'>
          <p className='micro-drawer-title'>{item.topic}</p>
        </div>

        <p
          style={{
            fontFamily: 'Satoshi',
            fontSize: '24px',
            color: '#1B1B26',
            marginTop: '24px',
            marginBottom: '12px'
          }}
        >
          {modules?.filter(el => el.active).length}/{modules?.length}
          <span
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#76767F',
              marginLeft: '8px'
            }}
          >
            active modules
          </span>
        </p>

        {modulesLoading && <LoadingIndicator />}

        {!modulesLoading &&
          modules &&
          modules.map((el, index) => (
            <ModuleItem
              modulesLoading={modulesLoading}
              item={el}
              index={index}
              key={el.trainingModuleId}
              onPreview={() => onModulePreview(el.trainingModuleId)}
            />
          ))}
      </div>
    </>
  )
}

const MicroTrainingItem = ({ item, onSelect }) => {
  return (
    <div className='micro-training-item column'>
      <div className='micro-training-item-rb micro-training-item-rb-green'></div>
      <div className='row'>
        <p className='micro-training-item-title'>{item.topic}</p>
        <Spacer />
        {/* <CyberSwitch onChange={() => onActivate(item.trainingId, !item?.active)} checked={item?.active} /> */}
      </div>
      <p
        style={{
          fontFamily: 'Satoshi',
          fontSize: '24px',
          color: '#1B1B26',
          marginTop: '24px',
          marginBottom: '12px'
        }}
      >
        {item?.activeModules?.length || 0}
        <span
          style={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#76767F',
            marginLeft: '8px'
          }}
        >
          active modules
        </span>
      </p>
      <div className='column' style={{ maxHeight: 120, overflow: 'hidden' }}>
        {item?.activeModules?.slice(0, 4).map(el => (
          <p
            key={el}
            style={{
              lineHeight: '24px'
            }}
          >
            {el}
          </p>
        ))}
      </div>
      {item?.activeModules?.length > 4 && (
        <p
          style={{
            fontWeight: '600',
            marginTop: '12px',
            fontSize: '14px',
            lineHeight: '22px',
            color: '#76767F'
          }}
        >
          + {item?.activeModules?.length - 4} more
        </p>
      )}
      <Spacer />
      <Button
        onClick={onSelect}
        sx={{
          color: '#6123FF',
          borderColor: '#E7E7EE',
          textTransform: 'none',
          height: '60px'
        }}
        startIcon={<EditIcon />}
        fullWidth
        variant={'outlined'}
      >
        View Modules
      </Button>
    </div>
  )
}

const Microtraining = () => {
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState(false)

  const [selectedId, setSelectedId] = useState(null)
  const [selectedModuleId, setSelectedModuleId] = useState(null)
  const [trainings, setTrainings] = useState(null)
  const [trainingsFiltred, setTrainingsFiltred] = useState(null)
  const [modules, setModules] = useState(null)
  const [modulesLoading, setModulesLoading] = useState(false)
  const [openModulePreview, setOpenModulePreview] = useState(false)
  const [modulePreview, setModulePreview] = useState(null)
  // const [modulePreviewLoading, setModulePreviewLoading] = useState(false)
  const [lang, setLang] = useState('English')

  const [loading, setLoading] = useState(false)

  const getTrainings = () => {
    setLoading(true)
    Promise.all([trainingsService.getTrainings(lang)]).then(
      values => {
        setLoading(false)
        const [trainingsResp] = values
        setTrainings(trainingsResp.data)
        setTrainingsFiltred(trainingsResp.data)
      },
      err => {
        if (err.response.status == 401) {
          navigate('/login')
        }
      }
    )
  }
  const getModules = () => {
    setModulesLoading(true)
    if (selectedId) {
      trainingsService.getModules(selectedId, lang).then(
        resp => {
          setModulesLoading(false)
          setModules(resp.data)
        },
        err => {
          if (err.response.status == 401) {
            navigate('/login')
          }
        }
      )
    }
  }

  useEffect(() => {
    getTrainings()
  }, [lang])

  useEffect(() => {
    getModules()
  }, [selectedId, openDrawer, lang])

  useEffect(() => {
    if (selectedModuleId) {
      // setModulePreviewLoading(true)
      trainingsService.getModulePreview(selectedId, selectedModuleId, lang).then(resp => {
        setModulePreview(resp.data)
        // setModulePreviewLoading(false)
      })
    }
  }, [selectedModuleId])

  const onSelectMicro = id => {
    setSelectedId(id)
    setOpenDrawer(true)
  }

  const onActivateTraining = (id, active) => {
    trainingsService.enable(id, active).then(() => {
      setTrainingsFiltred(trainings.map(el => (el.trainingId == id ? { ...el, active: active } : el)))
    })
  }

  const handleModulePreview = id => {
    setSelectedModuleId(id)
    setOpenModulePreview(true)
  }
  return (
    <div style={{ backgroundColor: '#F8F8FB', padding: '24px' }}>
      <div className='row row-center'>
        <h1>Training Topics</h1>
        <div className='spacer'></div>
        <RadioSelect
          style={{ marginRight: 20 }}
          options={['English', 'Polish']}
          value={lang}
          onSelectOption={el => {
            setLang(el)
          }}
        />
        {/* <RadioSelect options={['All Microtraining', 'Active', 'Inactive']} onSelectOption={handleFilter} /> */}
      </div>
      <div className='micro-training-items row'>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {trainingsFiltred &&
              trainingsFiltred.map(el => (
                <MicroTrainingItem
                  onActivate={onActivateTraining}
                  onSelect={() => onSelectMicro(el.trainingId)}
                  key={el.trainingId}
                  item={el}
                />
              ))}
          </>
        )}
      </div>

      <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <MicroTrainingPreview
          modules={modules}
          modulesLoading={modulesLoading}
          item={trainings?.find(el => el.trainingId == selectedId)}
          onClose={() => setOpenDrawer(false)}
          onModulePreview={handleModulePreview}
          lang={lang}
          onLangChange={l => setLang(l)}
        />
      </Drawer>

      {openModulePreview && modulePreview && (
        <div className='cards-wrapper'>
          <TrainingCardsV3 onClose={() => setOpenModulePreview(false)} modulePreview={modulePreview} />
        </div>
      )}
    </div>
  )
}

export default Microtraining
