import React, { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'

import { SortHeader } from '..'

import UserAvatar from 'src/common/components/UserAvatar'
import LoadingIndicator from 'src/common/components/LoadingIndicator'

import workflowsService from 'src/services/workflows.service'
import { UserViewContext } from 'src/App'
import utc from 'dayjs/plugin/utc'

const UsersTab = ({ id }) => {
  dayjs.extend(utc)
  const [users, setUsers] = useState([])
  const [usersSorted, setUsersSorted] = useState([])
  const [sortBy, setSortBy] = useState(null)
  const [loading, setLoading] = useState(false)

  const [userViewId, setUserViewId] = useContext(UserViewContext)

  const handleSort = field => {
    const fieldSet = {
      'Email & Name': 'userFirstName',
      'Trigger last date': 'triggeredAt'
    }
    const fieldName = fieldSet[field]
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
    setUsersSorted(
      users.sort((a, b) => {
        if (a[fieldName] < b[fieldName]) {
          return sortBy?.asc ? -1 : 1
        }
        if (a[fieldName] > b[fieldName]) {
          return sortBy?.asc ? 1 : -1
        }
        return 0
      })
    )
  }

  useEffect(() => {
    setLoading(true)
    workflowsService
      .getWorkflowUsers(id)
      .then(resp => {
        setLoading(false)
        setUsers(resp.data)
        setUsersSorted(resp.data)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  return (
    <>
      <h2
        style={{
          fontSize: '24px',
          fontFamily: 'Satoshi',
          marginBottom: '20px'
        }}
      >
        Users
      </h2>

      <div className='row row-center groups-list-heading'>
        <SortHeader name='Email & Name' sort={sortBy} onSort={handleSort} width={'50%'} />
        <SortHeader name='Trigger last date' sort={sortBy} onSort={handleSort} width={'50%'} />
      </div>

      <div style={{ height: 'calc(100vh - 360px)', overflow: 'auto' }}>
        {loading && <LoadingIndicator />}
        {usersSorted?.length == 0 ? (
          <div
            style={{
              width: '100%',
              marginTop: '50px',
              color: 'rgb(118, 118, 127)'
            }}
            className='column column-center'
          >
            {' '}
            <p>no records yet</p>
          </div>
        ) : (
          usersSorted &&
          usersSorted.map(el => {
            const {
              triggeredAt,
              userFirstName,
              userLastName,
              userEmail,
              // status,
              userId
            } = el

            return (
              <div key={userId} className='groups-list-item row row-center'>
                <div onClick={() => setUserViewId(userId)} style={{ width: '50%' }} className='row row-center'>
                  <UserAvatar style={{ background: '#fff' }} firstName={userFirstName} lastName={userLastName} />
                  <div className='column' style={{ maxWidth: '320px' }}>
                    <p
                      style={{
                        color: '#76767F',
                        fontSize: '14px',
                        marginBottom: '6px'
                      }}
                    >
                      {userEmail}
                    </p>
                    <p
                      className='one-sthing'
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                    >
                      {userFirstName + ' ' + userLastName}
                    </p>
                  </div>
                </div>
                <div style={{ width: '50%', color: '#76767F' }}>
                  {triggeredAt ? dayjs.utc(triggeredAt).local().format('D MMM HH:mm') : '—'}
                </div>
              </div>
            )
          })
        )}

        {}
      </div>
    </>
  )
}

export default UsersTab
