import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as EmailIcon } from '../../../../common/icons/envelopIcon.svg'
import { ReactComponent as ManualEmailIcon } from '../../../../common/icons/articleIcon.svg'
import { ReactComponent as SMSIcon } from '../../../../common/icons/SMSIcon.svg'
import { ReactComponent as WhatsUpIcon } from '../../../../common/icons/whatsUpIcon.svg'
import { ReactComponent as SlackIcon } from '../../../../common/icons/slackIcon.svg'
import { ReactComponent as PhoneIcon } from '../../../../common/icons/phoneIcon.svg'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export default function ActivityModalCard({ type, sequenceNumber, status, browser, ipAddress, location, actionAt }) {
  const Icon = iconMapper[type]
  return (
    <Box
      display={'flex'}
      columnGap={4}
      bgcolor={'#F8F8FB'}
      borderBottom={'2px solid #E7E8EA'}
      pt={2}
      pb={2}
      width={'100%'}
    >
      <Box display={'flex'} columnGap={2} alignItems={'center'} width={'35%'}>
        <Icon
          style={{
            height: '36px',
            width: '36px'
          }}
        />
        <Box display={'flex'} flexDirection={'column'} rowGap={0.5}>
          <Typography fontWeight={600} fontSize={16} fontFamily={'Plus Jakarta Sans'}>
            {typeMapperName[type]}
          </Typography>

          <Typography fontWeight={500} fontSize={14} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
            Step {sequenceNumber}
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} rowGap={1} width={'60%'} flexDirection={'column'}>
        <Typography fontWeight={600} fontSize={16} fontFamily={'Plus Jakarta Sans'} color={'#1B1B26'}>
          {statusMapper[status]}
        </Typography>
        <Typography fontWeight={500} fontSize={14} fontFamily={'Plus Jakarta Sans'} color={'#76767F'}>
          {convertToUserTimezone(actionAt)} • {location || 'N/A'} • IP: {ipAddress || 'N/A'} • {browser || 'N/A'}
        </Typography>
      </Box>
    </Box>
  )
}

dayjs.extend(utc)
dayjs.extend(timezone)

const convertToUserTimezone = serverTime => {
  const userTimezone = dayjs.tz.guess()
  const parsedTime = dayjs.utc(serverTime, 'YYYY-MM-DD HH:mm')
  return parsedTime.tz(userTimezone).format('HH:mm')
}

const iconMapper = {
  AUTOMATED_EMAIL: EmailIcon,
  MANUAL_EMAIL: ManualEmailIcon,
  SMS: SMSIcon,
  WHATSAPP: WhatsUpIcon,
  SLACK: SlackIcon,
  PHONE: PhoneIcon
}
const typeMapperName = {
  AUTOMATED_EMAIL: 'Automatic Email',
  MANUAL_EMAIL: 'Personalized Emails',
  SMS: 'Automatic SMS',
  WHATSAPP: 'Automatic WhatsApp message',
  SLACK: 'Automatic Slack message',
  PHONE: 'Automatic phone call'
}
const statusMapper = {
  SENT: 'Sent',
  OPENED: 'Opened',
  PHISHED: 'Phished',
  REPORTED: 'Reported',
  REPLIED: 'Replied',
  LINK_CLICKED: 'Link Clicked',
  ATTACH_LINK_CLICKED: 'Attach opened',
  QR_CODE_LINK_CLICKED: 'QR Code Scanned'
}
