import { QrCode2, Reply, ReplyAllOutlined } from '@mui/icons-material'
import React from 'react'

export const activityTypes = {
  openFishingSimEmail: 'SIMULATION_EMAIL_OPENED',

  openFishingSimLink: 'SIMULATION_LINK_CLICKED',
  reportedPhishingSimEmail: 'SIMULATION_REPORTED',
  reportedRealEmail: 'SUSPICIOUS_EMAIL_REPORTED',
  completedMicroTraining: 'SIMULATION_MICRO_TRAINING_COMPLETED',
  completedTrainingModule: 'TRAINING_PACKAGE_COMPLETED',
  missedMicroTrainingModule: 'SIMULATION_MICRO_TRAINING_MISSED',
  openQr: 'SIMULATION_QR_CODE_LINK_CLICKED',
  simulationReplied: 'SIMULATION_REPLIED'
}

const ActivityIcon = ({ type }) => {
  if (type == activityTypes.openFishingSimEmail) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path opacity='0.2' d='M35.25 20.25L26.0455 26.8125H21.9545L12.75 20.25L24 12.75L35.25 20.25Z' fill='#76767F' />
        <path
          d='M12.75 20.25V32.4375C12.75 32.6861 12.8488 32.9246 13.0246 33.1004C13.2004 33.2762 13.4389 33.375 13.6875 33.375H34.3125C34.5611 33.375 34.7996 33.2762 34.9754 33.1004C35.1512 32.9246 35.25 32.6861 35.25 32.4375V20.25L24 12.75L12.75 20.25Z'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.9526 26.8125L13.0371 33.1101'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M34.9605 33.1101L26.0449 26.8125'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.25 20.25L26.0455 26.8125H21.9545L12.75 20.25'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.openFishingSimLink) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          d='M20.0215 27.9803L27.9764 20.0253'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.9893 29.9677L22.6747 33.2823C21.6199 34.3372 20.1891 34.9298 18.6973 34.9298C17.2054 34.9298 15.7747 34.3372 14.7198 33.2823C13.6649 32.2274 13.0723 30.7966 13.0723 29.3048C13.0723 27.8129 13.6649 26.3822 14.7198 25.3273L18.0344 22.0128'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.9686 25.9886L33.2832 22.6741C34.3378 21.6191 34.9302 20.1885 34.93 18.6968C34.9299 17.2051 34.3373 15.7746 33.2825 14.7198C32.2277 13.665 30.7972 13.0724 29.3055 13.0723C27.8138 13.0721 26.3832 13.6645 25.3282 14.7191L22.0137 18.0337'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.missedMicroTrainingModule) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          d='M20.0215 27.9803L27.9764 20.0253'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.9893 29.9677L22.6747 33.2823C21.6199 34.3372 20.1891 34.9298 18.6973 34.9298C17.2054 34.9298 15.7747 34.3372 14.7198 33.2823C13.6649 32.2274 13.0723 30.7966 13.0723 29.3048C13.0723 27.8129 13.6649 26.3822 14.7198 25.3273L18.0344 22.0128'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.9686 25.9886L33.2832 22.6741C34.3378 21.6191 34.9302 20.1885 34.93 18.6968C34.9299 17.2051 34.3373 15.7746 33.2825 14.7198C32.2277 13.665 30.7972 13.0724 29.3055 13.0723C27.8138 13.0721 26.3832 13.6645 25.3282 14.7191L22.0137 18.0337'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.reportedPhishingSimEmail) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          opacity='0.2'
          d='M12.75 21.1875H18.375V33.375H12.75C12.5014 33.375 12.2629 33.2762 12.0871 33.1004C11.9113 32.9246 11.8125 32.6861 11.8125 32.4375V22.125C11.8125 21.8764 11.9113 21.6379 12.0871 21.4621C12.2629 21.2863 12.5014 21.1875 12.75 21.1875Z'
          fill='#00BC62'
        />
        <path
          d='M12.75 21.1875H18.375V33.375H12.75C12.5014 33.375 12.2629 33.2762 12.0871 33.1004C11.9113 32.9246 11.8125 32.6861 11.8125 32.4375V22.125C11.8125 21.8764 11.9113 21.6379 12.0871 21.4621C12.2629 21.2863 12.5014 21.1875 12.75 21.1875V21.1875Z'
          stroke='#00BC62'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.375 21.1875L23.0625 11.8125C23.555 11.8125 24.0426 11.9095 24.4976 12.098C24.9525 12.2864 25.3659 12.5626 25.7141 12.9108C26.0624 13.2591 26.3386 13.6725 26.527 14.1274C26.7155 14.5824 26.8125 15.07 26.8125 15.5625V18.375H34.0635C34.3294 18.375 34.5922 18.4315 34.8346 18.5409C35.0769 18.6502 35.2932 18.8098 35.4692 19.0091C35.6451 19.2084 35.7767 19.4429 35.8551 19.6969C35.9335 19.9509 35.957 20.2188 35.9241 20.4826L34.5178 31.7326C34.4611 32.186 34.2408 32.6032 33.8982 32.9056C33.5556 33.2081 33.1143 33.375 32.6573 33.375H18.375'
          stroke='#00BC62'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.reportedRealEmail) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          opacity='0.2'
          d='M12.75 14.625H18.375V26.8125H12.75C12.5014 26.8125 12.2629 26.7137 12.0871 26.5379C11.9113 26.3621 11.8125 26.1236 11.8125 25.875V15.5625C11.8125 15.3139 11.9113 15.0754 12.0871 14.8996C12.2629 14.7238 12.5014 14.625 12.75 14.625Z'
          fill='#76767F'
        />
        <path
          d='M12.75 14.625H18.375V26.8125H12.75C12.5014 26.8125 12.2629 26.7137 12.0871 26.5379C11.9113 26.3621 11.8125 26.1236 11.8125 25.875V15.5625C11.8125 15.3139 11.9113 15.0754 12.0871 14.8996C12.2629 14.7238 12.5014 14.625 12.75 14.625V14.625Z'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.375 26.8125L23.0625 36.1875C23.555 36.1875 24.0426 36.0905 24.4976 35.902C24.9525 35.7136 25.3659 35.4374 25.7141 35.0891C26.0624 34.7409 26.3386 34.3275 26.527 33.8726C26.7155 33.4176 26.8125 32.93 26.8125 32.4375V29.625H34.0635C34.3294 29.625 34.5922 29.5685 34.8346 29.4591C35.0769 29.3498 35.2932 29.1902 35.4692 28.9909C35.6451 28.7916 35.7767 28.5571 35.8551 28.3031C35.9335 28.0491 35.957 27.7812 35.9241 27.5174L34.5178 16.2674C34.4611 15.814 34.2408 15.3968 33.8982 15.0944C33.5556 14.7919 33.1143 14.625 32.6573 14.625H18.375'
          stroke='#76767F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.completedMicroTraining) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          d='M34.3125 17.4375L21.1875 30.5619L14.625 24'
          stroke='#00BC62'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.completedTrainingModule) {
    return (
      <svg
        style={{ flexShrink: '0' }}
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='24' cy='24' r='24' fill='white' />
        <path
          d='M26.3438 18.8438L16.0312 29.1562L10.875 24.0002'
          stroke='#00BC62'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M37.1248 18.8438L26.8123 29.1562L24.0732 26.4173'
          stroke='#00BC62'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
  if (type == activityTypes.openQr) {
    return (
      <div
        style={{
          background: '#fff',
          width: 48,
          height: 48,
          borderRadius: 48,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <QrCode2 sx={{ opacity: 0.7 }} />
      </div>
    )
  }
  if (type == activityTypes.simulationReplied) {
    return (
      <div
        style={{
          background: '#fff',
          width: 48,
          height: 48,
          borderRadius: 48,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28px'
          height='28px'
          viewBox='0 0 32 32'
          id='i-reply'
          fill='none'
          stroke='#76767F'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        >
          <path d='M10 6 L3 14 10 22 M3 14 L18 14 C26 14 30 18 30 26' />
        </svg>
      </div>
    )
  }
}

export default ActivityIcon
