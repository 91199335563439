import React, { useState, useContext } from 'react'
import { Button } from '@mui/material'
import { ReactComponent as FilterIcon } from '../../../common/icons/filter_icon.svg'
import FilterMenuList, { filterNameMapper } from './FilterMenuList'
import { QueryParamsContext } from '../QueryParamsContext'

export default function FilterPopper() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { params } = useContext(QueryParamsContext)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterName =
    params.statuses.length === 0
      ? 'No status'
      : params.statuses.length === 3
        ? 'All status'
        : params.statuses.map(status => filterNameMapper[status]).join(', ')

  return (
    <div>
      <Button
        sx={{
          background: '#F7F7F7',
          borderRadius: '24px',
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          color: '#1C1C27',
          textTransform: 'none',
          border: 'none',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: '#B1B1B1'
          }
        }}
        variant={'contained'}
        startIcon={<FilterIcon />}
        disableElevation
        onClick={handleClick}
      >
        {filterName}
      </Button>
      <FilterMenuList open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  )
}
