import axios from 'axios'
import authHeader from './auth-header'
import { compactObject } from 'src/heplers'

const API_URL = window._env_.REACT_APP_API_HOST

class CompanyService {
  getCompany(id) {
    return axios.get(API_URL + '/v1/companies/' + id, {
      headers: authHeader()
    })
  }
  getCompanyConfigs() {
    return axios.get(API_URL + '/v1/company-configurations', {
      headers: authHeader()
    })
  }
  getCompanyConfigsSim() {
    return axios.get(API_URL + '/v1/company-configurations/simulations', {
      headers: authHeader()
    })
  }
  updateCompanyConfigsSim(state) {
    return axios.put(API_URL + '/v1/company-configurations/simulations', state, { headers: authHeader() })
  }
  updateCompanyConfigs(payload) {
    return axios.put(API_URL + '/v1/company-configurations', payload, {
      headers: authHeader()
    })
  }

  getCompanyConfigsThreats() {
    return axios.get(API_URL + '/v1/company-configurations/threats', {
      headers: authHeader()
    })
  }
  updateCompanyConfigsThreats(email) {
    return axios.put(
      API_URL + '/v1/company-configurations/threats',
      { notificationEmails: email },
      { headers: authHeader() }
    )
  }

  getCompanys(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies', {
      headers: authHeader(),
      params
    })
  }
  delete(id) {
    return axios.delete(API_URL + '/v1/companies/' + id, {
      headers: authHeader()
    })
  }
  deleteMany(ids) {
    return axios.delete(API_URL + '/v1/companies/', { data: ids }, { headers: authHeader() })
  }
  update(id, payload) {
    return axios.put(API_URL + '/v1/companies/current', payload, {
      headers: authHeader()
    })
  }
  updateSelectedServices(id, payload) {
    return axios.put(API_URL + '/v1/companies/current/used-products', payload, { headers: authHeader() })
  }
  getLanguages() {
    return axios.get(API_URL + '/v1/languages', { headers: authHeader() })
  }
  registerCompany(data) {
    return axios.post(API_URL + '/v1/companies/register', data, {})
  }
  validateDomain(email) {
    return axios.post(API_URL + '/v1/companies/register/validate-domain', { email: email }, {})
  }
}

export default new CompanyService()
