import React, { useContext, useEffect, useState } from 'react'

import { NavLink, useNavigate } from 'react-router-dom'
import './styles.css'

import Logo from '../logo'
import DashBoardIcon from '../icons/DashBoardIcon'
import ThreatsIcon from '../icons/threats'
import TeamIcon from '../icons/team'
import OverviewIcon from '../icons/overview'
import TemplatesIcon from '../icons/templates'
import MicrotrainingsIcon from '../icons/micro'
import TrainingIcon from '../icons/training'
import SettingsIcon from '../icons/settings'
import LogoutIcon from '../icons/logout'
import ExpandIcon from '../icons/expandicon'
import ExpandButton from '../icons/expandbutton'
import AuthService from 'src/services/auth.service'
import userService from 'src/services/user.service'
import GroupsIcon from '../icons/groups'
import WorkflowsIcon from '../icons/workflows'
import BreachesIcon from '../icons/breaches'
import { parseJwt } from 'src/heplers'
import Spacer from '../components/Spacer'
import { Collapse } from '@mui/material'
import { OnboardingContext, UserViewContext } from 'src/App'
import ManualSimIcon from '../icons/manual-sim'
import GetStartedIcon from '../icons/getStartedIcon'

function Nav() {
  const treshHoldHeight = 890
  const [collapsed, setCollaplsed] = useState(false)
  const [user, setUser] = useState(null)
  const [isCompactMenu, setIsCompactMenu] = useState(window.innerHeight < treshHoldHeight)

  const [riskManagementOpen, setRiskManagementOpen] = useState(
    ['/', '/threats', '/workflows', '/breaches'].includes(window.location.pathname)
  )
  const [workforceOpen, setWorkforceOpen] = useState(['/team', '/groups'].includes(window.location.pathname))
  const [simulationOpen, setSimulationOpen] = useState(
    ['/overview', '/templates', '/manual-simulation', '/new-campaign', '/edit-campaign'].includes(
      window.location.pathname
    )
  )
  const [awarenessOpen, setAwarenessOpen] = useState(
    ['/microtraining', '/training-packages'].includes(window.location.pathname)
  )

  const [userViewId, setUserViewId] = useContext(UserViewContext)
  const [onboarding] = useContext(OnboardingContext)

  const navigate = useNavigate()
  const handleLogout = () => {
    AuthService.logout()
      .then(() => {
        navigate('/login')
        localStorage.removeItem('user')
      })
      .catch(() => {
        localStorage.removeItem('user')
        navigate('/login')
      })
  }

  const handleShowAdmin = () => {
    if (user?.id) {
      setUserViewId(user.id)
    }
  }

  const handleResise = () => {
    if (window.innerHeight < treshHoldHeight && !isCompactMenu) {
      setIsCompactMenu(true)
    }
    if (window.innerHeight > treshHoldHeight && isCompactMenu) {
      setIsCompactMenu(false)
    }
  }

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem('user'))
    const userId = parseJwt(userLocal.accessToken).userId
    userService.getUser(userId).then(data => setUser(data.data))

    window.addEventListener('resize', handleResise)
    return () => {
      window.removeEventListener('resize', handleResise)
    }
  }, [])

  const handleOpenMenuGroup = (groupName, skipClose = false) => {
    setRiskManagementOpen(groupName == 'riskManagement' ? (skipClose ? true : !riskManagementOpen) : false)
    setWorkforceOpen(groupName == 'workforce' ? (skipClose ? true : !workforceOpen) : false)
    setSimulationOpen(groupName == 'simulation' ? (skipClose ? true : !simulationOpen) : false)
    setAwarenessOpen(groupName == 'awareness' ? (skipClose ? true : !awarenessOpen) : false)
  }

  return (
    <div className='navigation column'>
      <NavLink className={'logo-link'} to={'/'}>
        <Logo collapsed={collapsed} />
      </NavLink>
      {!onboarding.done && (
        <NavLink
          title='Get started'
          className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
          to={'/get-started'}
        >
          <GetStartedIcon /> {collapsed ? '' : <span>Get started</span>} <Spacer />{' '}
          <span
            style={{
              fontSize: 12,
              marginRight: 10,
              color: '#6123FF'
            }}
          >
            {onboarding?.activeStep - 1 || 0} of 4
          </span>
        </NavLink>
      )}
      <NavLink title='Dashboard' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/'}>
        <DashBoardIcon /> {collapsed ? '' : 'Dashboard'}
      </NavLink>

      {!collapsed && (
        <div
          className={`row row-center nav-separator${riskManagementOpen ? ' nav-separator-open' : ''}`}
          onClick={() => handleOpenMenuGroup('riskManagement')}
        >
          <p>Risk Management</p>
          <Spacer />
          <svg
            className='nav-open-ico'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M3 5.33334L8 10.3333L13 5.33334' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
      )}
      <Collapse
        onClick={() => handleOpenMenuGroup('riskManagement', true)}
        in={collapsed ? collapsed : riskManagementOpen}
      >
        <NavLink title='Threats' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/threats'}>
          <ThreatsIcon /> {collapsed ? '' : 'Threats'}
        </NavLink>
        <NavLink title='Workflows' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/workflows'}>
          <WorkflowsIcon /> {collapsed ? '' : 'Workflows'}
        </NavLink>
        <NavLink title='Breaches' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/breaches'}>
          <BreachesIcon /> {collapsed ? '' : 'Breaches'}
        </NavLink>
      </Collapse>

      {!collapsed && (
        <div
          className={`row row-center nav-separator${workforceOpen ? ' nav-separator-open' : ''}`}
          onClick={() => handleOpenMenuGroup('workforce')}
        >
          <p>Workforce</p>
          <Spacer />
          <svg
            className='nav-open-ico'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M3 5.33334L8 10.3333L13 5.33334' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
      )}
      <Collapse onClick={() => handleOpenMenuGroup('workforce', true)} in={collapsed ? collapsed : workforceOpen}>
        <NavLink title='Team' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/team'}>
          <TeamIcon /> {collapsed ? '' : 'Team'}
        </NavLink>
        <NavLink title='Groups' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/groups'}>
          <GroupsIcon /> {collapsed ? '' : 'Groups'}
        </NavLink>
      </Collapse>

      {!collapsed && (
        <div
          className={`row row-center nav-separator${simulationOpen ? ' nav-separator-open' : ''}`}
          onClick={() => handleOpenMenuGroup('simulation')}
        >
          <p>Simulation</p>
          <Spacer />
          <svg
            className='nav-open-ico'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M3 5.33334L8 10.3333L13 5.33334' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
      )}
      <Collapse onClick={() => handleOpenMenuGroup('simulation', true)} in={collapsed ? collapsed : simulationOpen}>
        <NavLink title='Overview' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/overview'}>
          <OverviewIcon /> {collapsed ? '' : 'Overview'}
        </NavLink>
        <NavLink title='Templates' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/templates'}>
          <TemplatesIcon /> {collapsed ? '' : 'Templates'}
        </NavLink>
        <NavLink
          title='Manual Simulation V2'
          className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
          to={'/simulation-campaign'}
        >
          <ManualSimIcon /> {collapsed ? '' : 'Simulation Campaigns'}
        </NavLink>
      </Collapse>

      {!collapsed && (
        <div
          className={`row row-center nav-separator${awarenessOpen ? ' nav-separator-open' : ''}`}
          onClick={() => handleOpenMenuGroup('awareness')}
        >
          <p>Awareness</p>
          <Spacer />
          <svg
            className='nav-open-ico'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M3 5.33334L8 10.3333L13 5.33334' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
      )}
      <Collapse onClick={() => handleOpenMenuGroup('awareness', true)} in={collapsed ? collapsed : awarenessOpen}>
        <NavLink
          title='Training Topics'
          className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
          to={'/microtraining'}
        >
          <MicrotrainingsIcon /> {collapsed ? '' : 'Training Topics'}
        </NavLink>
        <NavLink
          title='Training Packages'
          className={`nav-item row ${collapsed && 'nav-item-collapsed'}`}
          to={'/training-packages'}
        >
          <TrainingIcon /> {collapsed ? '' : 'Training Packages'}
        </NavLink>
      </Collapse>

      <Spacer />

      <NavLink title='Settings' className={`nav-item row ${collapsed && 'nav-item-collapsed'}`} to={'/settings'}>
        <SettingsIcon /> {collapsed ? '' : 'Settings'}
      </NavLink>
      {user && (
        <div className='row'>
          <div onClick={handleShowAdmin} className='nav-user row'>
            <div className='avatar'>{user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}</div>

            {!collapsed && (
              <>
                <div className='column'>
                  <p className='name'>{user.firstName + ' ' + user.lastName}</p>
                  {/* <p className='email'>{user.email}</p> */}
                </div>
                <Spacer />
              </>
            )}
          </div>
          {!collapsed && (
            <div onClick={handleLogout} className={`nav-item-single-icon`}>
              <LogoutIcon />
            </div>
          )}
        </div>
      )}

      <div className='collapse-menu' onClick={() => setCollaplsed(!collapsed)}>
        <ExpandButton className='collapse-menu-btn' />
        <div className={`collapse-menu-icon ${collapsed && 'collapse-menu-icon-collapsed'}`}>
          <ExpandIcon />
        </div>
      </div>
    </div>
  )
}

export default Nav
